import { NotificationField, NotificationType } from '../definition';

export const TEMP_PROPERTY_NOTIFICATIONS = '_temp_notifications';

export const FORM_FIELDS = Object.freeze({
  FAX_INBOUND_ERROR_TO_EMAIL: {
    ENABLED: `${TEMP_PROPERTY_NOTIFICATIONS}.${NotificationType.FaxInboundErrorToEmail}.${NotificationField.Enabled}`,
    FROM: `${TEMP_PROPERTY_NOTIFICATIONS}.${NotificationType.FaxInboundErrorToEmail}.${NotificationField.From}`,
    USE_VOICEMAIL_TO_EMAIL_FROM: `${TEMP_PROPERTY_NOTIFICATIONS}.${NotificationType.FaxInboundErrorToEmail}.${NotificationField.UseVoicemailToEmailFrom}`,
  },
  VOICEMAIL_TO_EMAIL: {
    FROM: `${TEMP_PROPERTY_NOTIFICATIONS}.${NotificationType.VoicemailToEmail}.${NotificationField.From}`,
  },
});
