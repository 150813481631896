import { JOI } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { NotificationField, NotificationToType, NotificationType } from '../definition';
import { TEMP_PROPERTY_NOTIFICATIONS } from './constant';

const templateSchema = () =>
  Joi.object({
    [NotificationField.Enabled]: Joi.boolean().required().allow(true, false),
    [NotificationField.From]: Joi.when(NotificationField.Enabled, {
      is: Joi.boolean().valid(true),
      then: Joi.string().required().email(JOI.EMAIL),
      otherwise: Joi.optional(),
    }),
    [NotificationField.Subject]: Joi.when(NotificationField.Enabled, {
      is: Joi.boolean().valid(true),
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
    [NotificationField.Send]: Joi.when(NotificationField.Enabled, {
      is: Joi.boolean().valid(true),
      then: Joi.object({
        [NotificationField.EmailAddresses]: Joi.when(
          `...${NotificationField.Send}.${NotificationField.Type}.${NotificationToType.Specified}`,
          {
            is: Joi.boolean().valid(true),
            then: Joi.array()
              .items(Joi.string().required().email(JOI.EMAIL))
              .required()
              .label(
                i18next.t(
                  'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.label.email_addresses',
                ),
              ),
            otherwise: Joi.optional(),
          },
        ),
        [NotificationField.Type]: Joi.object({
          [NotificationToType.AccountAdmins]: Joi.boolean(),
          // when sub-section is enabled, one of the checkboxes must be checked
          [NotificationToType.Specified]: Joi.when(Joi.ref(NotificationToType.AccountAdmins), {
            is: Joi.boolean().valid(false),
            then: Joi.boolean()
              .required()
              .valid(true)
              .messages(
                messages({
                  'any.only': i18next.t(
                    'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.error.type',
                  ),
                }),
              ),
            otherwise: Joi.optional(),
          }),
        }).unknown(true),
      }),
      otherwise: Joi.optional(),
    }),
  }).unknown(true);

export default () => ({
  [TEMP_PROPERTY_NOTIFICATIONS]: Joi.object({
    [NotificationType.VoicemailToEmail]: Joi.object({
      [NotificationField.From]: Joi.string().required().email(JOI.EMAIL),
    }).unknown(true),
    [NotificationType.FaxInboundErrorToEmail]: Joi.object({
      // use_voicemail_to_email_from exists on the form, but is not validated
      [NotificationField.From]: Joi.when(Joi.ref(NotificationField.UseVoicemailToEmailFrom), {
        is: Joi.boolean().valid(false),
        then: Joi.string().required().email(JOI.EMAIL),
        otherwise: Joi.optional(),
      }),
    }).unknown(true),
    [NotificationType.Deregister]: templateSchema(),
    [NotificationType.SeatCreated]: templateSchema(),
  })
    .unknown(true)
    .messages(messages()),
});
