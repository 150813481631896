import { themeColor } from 'theme';

export default (
  <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6811 15.7771L1.0561 0.152095C0.907129 0.00312031 0.683203 -0.0406297 0.488379 0.0395949C0.293554 0.11982 0.166504 0.310445 0.166504 0.520845V23.4375C0.166504 23.6573 0.305029 23.8541 0.512354 23.9281C0.718604 24.0031 0.950879 23.9375 1.09048 23.7677L5.25088 18.6833L8.02691 24.6979C8.11441 24.8875 8.30293 25 8.49981 25C8.56856 25 8.63833 24.9865 8.70503 24.9573L12.3509 23.3948C12.48 23.3396 12.5821 23.2344 12.6321 23.1032C12.6821 22.9719 12.678 22.8261 12.6186 22.6979L9.83423 16.6667H16.3124C16.5228 16.6667 16.7134 16.5396 16.7936 16.3448C16.8749 16.15 16.8301 15.9271 16.6811 15.7771Z"
      fill={themeColor.core.color.black}
    />
  </svg>
);
