import { TableCellCheckmarkIcon, TableCellStreetAddress } from 'apps/shared/components/TableCell';
import { CHARACTER } from 'constant';
import i18next from 'i18next';
import { Column } from 'react-table';
import { Justify } from 'shared/components/Table';
import { compareStrings } from 'shared/utility';

export const columns = (): Column[] => [
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.number') as string,
    accessor: 'id',
    width: 300,
  },
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.service_address') as string,
    Cell: ({ row }: { row: { original: { e911: { street_address?: string } } } }) => (
      <TableCellStreetAddress streetAddress={row.original.e911?.street_address} />
    ),
    accessor: 'e911.street_address',
    width: 300,
  },
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.outbound') as string,
    Cell: ({ row }: any) => row.original.cnam?.display_name ?? CHARACTER.EMDASH,
    accessor: 'cnam.display_name',
    width: 200,
  },
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.inbound') as string,
    Cell: ({ row }: any) => (
      <TableCellCheckmarkIcon isVisible={row.original.cnam?.inbound_lookup} />
    ),
    accessor: (row: any) => (row.cnam?.inbound_lookup ? 0 : 1),
    id: `numbers.numbers_in_use.inbound.${Justify.Center}`,
    width: 200,
  },
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.prepend') as string,
    Cell: ({ row }: any) => row.original?.prepend?.name ?? CHARACTER.EMDASH,
    accessor: 'prepend.name',
    width: 200,
  },
  {
    Header: i18next.t('numbers:containers.numbers_in_use.table.column.used_by') as string,
    Cell: ({ row }: any) => row.original.used_by_as_link,
    accessor: 'used_by',
    sortType: (rowA: any, rowB: any) =>
      compareStrings(rowA.original.used_by, rowB.original.used_by),
    width: 300,
  },
];
