import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';
import { StyledDownloadEndpointsButtonProps as Props } from './definition';

const StyledDownloadEndpointsButton = styled(Box)<Props>`
  ${(props) => css`
    > div {
      margin-bottom: ${props.theme.spacing(2)};
    }
    & button {
      padding-left: 0;
    }
  `};
`;

export default StyledDownloadEndpointsButton;
