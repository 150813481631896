import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { BaseSyntheticEvent, ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { EditableListJustify } from 'shared/components/EditableList';
import { LabeledCheckbox, LabeledEditableList, LabeledInput } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import { NotificationField, NotificationType } from '../../../definition';
import { TEMP_PROPERTY_NOTIFICATIONS } from '../../constant';
import { I18NEXT_OPTIONS } from './constant';
import { NotificationProps as Props } from './definition';
import translations from './translations';
import { getFormFields } from './utility';

const Notification = ({ type }: Props) => {
  const { t } = useTranslation();
  const { field, heading } = translations()[type];
  const FORM_FIELDS = getFormFields(type);

  const {
    formState: { errors },
    getValues,
    resetField,
    setValue,
    watch,
  } = useFormContext();

  const watchFields = {
    enabled: watch(FORM_FIELDS.ENABLED),
    sendTypeSpecified: watch(FORM_FIELDS.SEND.TYPE.SPECIFIED),
  };

  const onChange = (type: string) => ({ target: { checked } }: BaseSyntheticEvent) => {
    const prefix = `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.`;

    if (checked === false) {
      const fields = [
        NotificationField.From,
        `${NotificationField.Send}.${NotificationField.Type}`,
        `${NotificationField.Send}.${NotificationField.EmailAddresses}`,
      ];

      if (
        type === NotificationType.Deregister &&
        getValues(FORM_FIELDS.SUBJECT) !==
          t(
            'accounts_manager:containers.accounts.section.notifications.template.deregister.subject',
            I18NEXT_OPTIONS,
          )
      ) {
        fields.splice(1, 0, NotificationField.Subject);
      }

      fields.forEach((field: string) => resetField(`${prefix}${field}`));
    }

    const enabled = `${prefix}${NotificationField.Enabled}`;
    setValue(enabled, checked, { shouldDirty: checked !== getValues(enabled) });
  };

  const updateField = useCallback(
    (field: string, value: string) => {
      const fieldName = `${TEMP_PROPERTY_NOTIFICATIONS}.${field}`;

      const local = {
        errors: errors?.[TEMP_PROPERTY_NOTIFICATIONS] as any,
      };

      const setField = (isError: boolean, name: string, value: string): void =>
        isError
          ? resetField(name, { defaultValue: getValues(name), keepDirty: false })
          : setValue(name, value, { shouldDirty: true });

      if (field === `${type}.${NotificationField.From}`) {
        setField(Boolean(local.errors?.[type]?.from), fieldName, value);
      }

      if (field === `${type}.${NotificationField.Send}.${NotificationField.EmailAddresses}`) {
        setField(Boolean(local.errors?.[type]?.send?.email_addresses), fieldName, value);
      }
    },
    [errors, type, getValues, resetField, setValue],
  );

  return (
    <>
      <Typography className="MuiTypography-block" variant="h2">
        {heading}
      </Typography>
      <Box role="row">
        <Box role="cell">
          {/* Enabled */}
          <HookFormInputWrapper isCheckbox name={FORM_FIELDS.ENABLED}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                isDirty={isDirty}
                checkboxProps={{
                  ...formProps,
                  onChange: onChange(type),
                }}
                indentWidth="large"
                label={field.enabled.label}
                tooltip={field.enabled.info}
                tooltipWidth="medium"
              />
            )}
          </HookFormInputWrapper>

          {/* Send From */}
          {watchFields.enabled && (
            <>
              <HookFormInputWrapper name={FORM_FIELDS.FROM}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    isDirty={isDirty}
                    feedback={feedback}
                    inputProps={{
                      ...formProps,
                      placeholder: t(
                        'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                      ),
                      onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                        updateField(`${type}.${NotificationField.From}`, value),
                    }}
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                    )}
                    labelProps={{ required: true }}
                  />
                )}
              </HookFormInputWrapper>

              {/* Subject */}
              {type === NotificationType.Deregister &&
                getValues(FORM_FIELDS.SUBJECT) !==
                  t(
                    'accounts_manager:containers.accounts.section.notifications.template.deregister.subject',
                    I18NEXT_OPTIONS,
                  ) && (
                  <HookFormInputWrapper name={FORM_FIELDS.SUBJECT}>
                    {({ ref, isDirty, feedback, ...formProps }) => (
                      <LabeledInput
                        isDirty={isDirty}
                        feedback={feedback}
                        inputProps={{
                          ...formProps,
                          placeholder: t(
                            'accounts_manager:containers.accounts.section.notifications.field.subject.label',
                          ),
                        }}
                        label={t(
                          'accounts_manager:containers.accounts.section.notifications.field.subject.label',
                        )}
                        labelProps={{ required: true }}
                      />
                    )}
                  </HookFormInputWrapper>
                )}

              {/* Emails to Notify (Admins) */}
              <HookFormInputWrapper isCheckbox name={FORM_FIELDS.SEND.TYPE.ADMINS}>
                {({ ref, isDirty, ...formProps }) => (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                    }}
                    groupLabel={t(
                      'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.label.group',
                    )}
                    groupLabelProps={{ required: true }}
                    groupLabelWidth="large"
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.checkbox.label.admins',
                    )}
                    tooltip={t(
                      'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.checkbox.info.admins',
                    )}
                    tooltipWidth="medium"
                  />
                )}
              </HookFormInputWrapper>

              {/* Emails to Notify (Specific) */}
              <HookFormInputWrapper isCheckbox name={FORM_FIELDS.SEND.TYPE.SPECIFIED}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledCheckbox
                    hasMargin
                    hasValidationMargin
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                    }}
                    feedback={feedback}
                    indentWidth="large"
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.checkbox.label.specified',
                    )}
                    tooltip={t(
                      'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.checkbox.info.specified',
                    )}
                    tooltipWidth="medium"
                  />
                )}
              </HookFormInputWrapper>

              {/* Emails to Notify (Email Addresses) */}
              {watchFields.sendTypeSpecified && (
                <HookFormInputWrapper name={FORM_FIELDS.SEND.EMAIL_ADDRESSES}>
                  {({ isDirty, feedback, value, onChange }) => (
                    <LabeledEditableList
                      isDirty={isDirty}
                      editableListProps={{
                        data: {
                          columns: [
                            {
                              header: {
                                text: t(
                                  'accounts_manager:containers.accounts.section.notifications.field.email_to_notify.label.email_addresses',
                                ),
                                justify: EditableListJustify.Left,
                              },
                              input: {
                                validationMask: (value: string) => Boolean(value),
                              },
                            },
                          ],
                          rows: value ? value.map((item: string) => [item]) : [],
                        },
                        onUpdate: (data: any) =>
                          onChange(data.map(([item]: Array<string>) => item)),
                      }}
                      feedback={feedback}
                    />
                  )}
                </HookFormInputWrapper>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Notification;
