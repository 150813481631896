import { JOI } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export default () => ({
  contact: Joi.object({
    billing: Joi.object({
      email: Joi.string().email(JOI.EMAIL).optional().allow(''),
    }).unknown(true),
    technical: Joi.object({
      /**
       * same_as_billing exists on the form and will exist in the
       * data object, but is not validated as part of the schema
       */
      email: Joi.when(Joi.ref('same_as_billing'), {
        is: Joi.boolean().valid(false),
        then: Joi.string().email(JOI.EMAIL).optional().allow(''),
        otherwise: Joi.optional(),
      }),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages()),
});
