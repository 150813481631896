import i18next from 'i18next';

const translations = () => ({
  dialog: {
    title: i18next.t('phone_system:containers.callflows.dialog.help.content.title.1'),
    subtitle: i18next.t('phone_system:containers.callflows.dialog.help.content.subtitle.1'),
  },
  panel: {
    0: {
      title: i18next.t('phone_system:containers.callflows.dialog.help.content.panel.1.intro.title'),
      subtitle: i18next.t(
        'phone_system:containers.callflows.dialog.help.content.panel.1.intro.subtitle',
      ),
    },
    1: {
      title: i18next.t('phone_system:containers.callflows.dialog.help.content.panel.2.intro.title'),
      subtitle: i18next.t(
        'phone_system:containers.callflows.dialog.help.content.panel.2.intro.subtitle',
      ),
    },
    2: {
      title: i18next.t('phone_system:containers.callflows.dialog.help.content.panel.3.intro.title'),
      subtitle: i18next.t(
        'phone_system:containers.callflows.dialog.help.content.panel.3.intro.subtitle',
      ),
    },
  },
});

export default translations;
