import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { prepareDataForTable } from 'shared/components/NumbersInUsePicker/utility';
import Table from 'shared/components/Table';
import useBridgeNumber from 'shared/hooks/useBridgeNumber';
import { columns } from './utility';

const List: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: phoneNumbersData, isLoading: isPhoneNumbersLoading } = useFetchPhoneNumbersQuery();
  const { data: callflowsData, isLoading: isCallflowsLoading } = useFetchCallflowsQuery();
  const { bridgeNumber, isLoading: isAccountLoading } = useBridgeNumber();

  if (isPhoneNumbersLoading || isCallflowsLoading || isAccountLoading) {
    return <Loading hasLargeProgress hasLabel={false} />;
  }

  return (
    <Table
      hasPagination={{ rowsPerPage: true }}
      title={t('numbers:containers.numbers_in_use.table.title')}
      columns={columns()}
      data={prepareDataForTable(phoneNumbersData, bridgeNumber, callflowsData, true)}
      hasSearch
    />
  );
};

export default List;
