import FormAddressAlertRow from 'apps/shared/components/FormAddressAlertRow';
import { FormContext } from 'apps/shared/components/FormContext';
import FormNumbersInUsePicker from 'apps/shared/components/FormNumbersInUsePicker';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'presence_id',
  'caller_id.internal.name',
  'caller_id.internal.number',
  'caller_id.external.name',
  'caller_id.external.number',
  'caller_id.emergency.name',
  'caller_id.emergency.number',
];

const CallerIdSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);

  return (
    <>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="presence_id">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-presence"
                inputProps={{
                  ...formProps,
                }}
                label={t('phone_system:containers.devices.section.caller_id.presence')}
              />
            )}
          </HookFormInputWrapper>

          <HookFormInputWrapper name="should_create_caller_id" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                checkboxProps={{ ...formProps }}
                id="checkbox-caller-id-create-name"
                indentWidth="large"
                label={t(
                  'phone_system:containers.devices.section.caller_id.should_create_caller_id',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.devices.section.caller_id.in_house_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-inhouse-id-name"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                label={t('phone_system:containers.devices.section.caller_id.caller_id_name.label')}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-inhouse-id-number"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.devices.section.caller_id.caller_id_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.devices.section.caller_id.outbound_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-outbound-calls-name"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                label={t('phone_system:containers.devices.section.caller_id.caller_id_name.label')}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-outbound-calls-number"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.devices.section.caller_id.caller_id_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.devices.section.caller_id.emergency_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-emergency-calls-name"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                label={t('phone_system:containers.devices.section.caller_id.caller_id_name.label')}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                id="input-caller-id-emergency-calls-number"
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.devices.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                label={t(
                  'phone_system:containers.devices.section.caller_id.caller_id_number.label',
                )}
              />
            )}
          </HookFormInputWrapper>
          <FormNumbersInUsePicker field={`${contextName}caller_id.emergency.number`} />
        </div>
      </div>

      <FormAddressAlertRow field={`${contextName}caller_id.emergency.number`} />
    </>
  );
};

export default CallerIdSection;
