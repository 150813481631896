import { HelpDialogIntro } from 'apps/shared/components/HelpDialog';
import Box from 'shared/components/Box';
import Typography from 'shared/components/Typography';
import images from '../images';
import { getClasses } from '../utility';
import { StyledHelpDialogEdit } from './style';
import translations from './translations';

const HelpDialogEdit = () => {
  const { dialog, panel } = translations();
  const classes = getClasses();

  return (
    <StyledHelpDialogEdit>
      <Typography classes={classes.typography.default} variant="h1">
        {dialog.title}
      </Typography>
      <Typography classes={classes.typography.default} variant="h3">
        {dialog.subtitle}
      </Typography>
      <Box>
        <Box>
          <HelpDialogIntro number={1}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[0].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[0].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <Box className="svg callflow-0">{images.svg.callflow[0]}</Box>
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={2}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[1].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[1].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <Box className="svg callflow-1">{images.svg.callflow[1]}</Box>
            <Box className="svg arrow">{images.svg.arrow}</Box>
            <img src={images.png.actions} alt="actions" className="png actions" />
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={3}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[2].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[2].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <img src={images.png.callflow} alt="actions" className="png callflow" />
          </Box>
        </Box>
      </Box>
    </StyledHelpDialogEdit>
  );
};

export default HelpDialogEdit;
