type AssignedEndpoint<V> = { [e911Number: string]: [V] | [] };

export enum AssignedEndpointsType {
  Account = 'assignedAccount',
  Devices = 'assignedDevices',
  Users = 'assignedUsers',
}

export type AssignedEndpoints = {
  [AssignedEndpointsType.Account]: string;
  [AssignedEndpointsType.Devices]: AssignedDevices;
  [AssignedEndpointsType.Users]: AssignedUsers;
};

export type AssignedUserValue = {
  username: string;
  first_name: string;
  last_name: string;
};

export type AssignedDeviceValue = AssignedUserValue & {
  device_name: string;
};

export type AssignedUsers = AssignedEndpoint<AssignedUserValue>;
export type AssignedDevices = AssignedEndpoint<AssignedDeviceValue>;
