import { createContext } from 'react';
import { AssignedEndpoints, AssignedEndpointsType } from './utility/definition';

export const E911AssignedEndpointsContext = createContext({
  fetchAssignedEndpoints: () => {},
  assignedEndpoints: ({
    [AssignedEndpointsType.Account]: '',
    [AssignedEndpointsType.Devices]: {},
    [AssignedEndpointsType.Users]: {},
  } as unknown) as AssignedEndpoints,
});
