const defaultCsvBlobValues = {
  e911: {
    street_address: '',
    extended_address: '',
    locality: '',
    region: '',
    postal_code: '',
    customer_name: '',
    notification_contact_emails: [],
  },
};

export default defaultCsvBlobValues;
