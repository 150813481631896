import i18next from 'i18next';
import { DialogState } from 'shared/components/Dialog';
import { LabeledInput } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { AccountDialog } from '../definition';

export const KEYWORD_DELETE = 'DELETE';

export const getDialogs = (
  setIsDialogOpen: any,
  accountName = '',
  onInputChange: any,
): { [key: string]: AccountDialog } => ({
  delete: {
    content: {
      element: (
        <>
          <span>
            {i18next.t(
              'accounts_manager:containers.accounts.section.basics.field.delete_account.dialog.content.0',
            )}
          </span>
          <span>
            {i18next.t(
              'accounts_manager:containers.accounts.section.basics.field.delete_account.dialog.content.1',
              { name: accountName },
            )}
          </span>
          <span>
            {i18next.t(
              'accounts_manager:containers.accounts.section.basics.field.delete_account.dialog.content.2',
              { keyword: KEYWORD_DELETE },
            )}
          </span>
          <LabeledInput
            inputWidth="small"
            label="Delete Account Confirm Text"
            labelWidth="none"
            inputProps={{
              onChange: onInputChange,
            }}
          />
        </>
      ),
      isSelectable: false,
    },
    title: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.delete_account.dialog.title',
    ),
    action: {
      color: 'error',
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.delete_account.dialog.action.label',
      ),
    },
    handleClick: (isOpen = true) =>
      setIsDialogOpen((isDialogOpen: DialogState) => ({ ...isDialogOpen, delete: isOpen })),
  },
  demote: {
    content: {
      element: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.demote_account.dialog.content',
      ),
    },
    title: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.demote_account.dialog.title',
    ),
    action: {
      color: 'secondary',
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.demote_account.dialog.action.label',
      ),
    },
    handleClick: (isOpen = true) =>
      setIsDialogOpen((isDialogOpen: DialogState) => ({ ...isDialogOpen, demote: isOpen })),
  },
  disable: {
    content: {
      element: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.disable_account.dialog.content',
      ),
    },
    title: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.disable_account.dialog.title',
    ),
    action: {
      color: 'secondary',
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.disable_account.dialog.action.label',
      ),
    },
    handleClick: (isOpen = true) =>
      setIsDialogOpen((isDialogOpen: DialogState) => ({ ...isDialogOpen, disable: isOpen })),
  },
  enable: {
    content: {
      element: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.enable_account.dialog.content',
      ),
    },
    title: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.enable_account.dialog.title',
    ),
    action: {
      color: 'secondary',
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.enable_account.dialog.action.label',
      ),
    },
    handleClick: (isOpen = true) =>
      setIsDialogOpen((isDialogOpen: DialogState) => ({ ...isDialogOpen, enable: isOpen })),
  },
  promote: {
    content: {
      element: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.promote_account.dialog.content',
      ),
    },
    title: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.promote_account.dialog.title',
    ),
    action: {
      color: 'secondary',
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.promote_account.dialog.action.label',
      ),
    },
    handleClick: (isOpen = true) =>
      setIsDialogOpen((isDialogOpen: DialogState) => ({ ...isDialogOpen, promote: isOpen })),
  },
});

export const getOptions = (): { [key: string]: SelectOption[] } => ({
  ACCOUNT_TYPE: [
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.white_label',
      ),
      value: 'white_label',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.grey_label',
      ),
      value: 'grey_label',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.master_agent',
      ),
      value: 'master_agent',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.sub_agent',
      ),
      value: 'sub_agent',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.direct_agent',
      ),
      value: 'direct_agent',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.parter_child',
      ),
      value: 'parter_child',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.pro_bono',
      ),
      value: 'pro_bono',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.direct_client',
      ),
      value: 'direct_client',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.sandbox',
      ),
      value: 'sandbox',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.account_type.options.other',
      ),
      value: 'other',
    },
  ],
  LANGUAGE: [
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.language.options.auto',
      ),
      value: 'auto',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.language.options.en-US',
      ),
      value: 'en-US',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.language.options.fr-FR',
      ),
      value: 'fr-FR',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.language.options.de-DE',
      ),
      value: 'de-DE',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.basics.field.language.options.ru-RU',
      ),
      value: 'ru-RU',
    },
  ],
});

export const getDeleteAccountErrorMessage = (error: any): string => {
  const ERROR = {
    DEFAULT: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.delete_account.error.default',
    ),
    HAS_DESCENDANTS: i18next.t(
      'accounts_manager:containers.accounts.section.basics.field.delete_account.error.has_descendants',
    ),
  };
  return ERROR[
    error?.data?.data?.message === 'account has descendants' ? 'HAS_DESCENDANTS' : 'DEFAULT'
  ];
};

/** Return the corresponding action of Mattermost team when the account status is changed */
export const getMattermostTeamActionOnAccountsStatusChange = ({
  accountId,
  deleteMattermostTeam,
  isTeamChatEnabled,
  nextIsAccountEnabled,
}: {
  accountId?: string;
  deleteMattermostTeam: any;
  nextIsAccountEnabled: boolean;
  isTeamChatEnabled: boolean;
}) => {
  // Disabling account
  if (!nextIsAccountEnabled && isTeamChatEnabled) {
    return async () => deleteMattermostTeam({ accountId }).unwrap();
  }
  return async () => {};
};
