import TableCellShareIcon from 'apps/PhoneSystem/shared/TableCellSharedIcon';
import { TableCellCheckmarkIcon, TableCellMaskedValue } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { uniqBy } from 'lodash';
import { FieldValues } from 'react-hook-form';
import { Cell, Justify, TABLE_CONSTANTS } from 'shared/components/Table';

export const columns = (data: any) => [
  {
    Header: i18next.t('phone_system:containers.seats.voicemail.table.column.shared_vmbox.header'),
    Cell: ({ value }: Cell<JSX.Element>) => <TableCellShareIcon data={data} id={value} />,
    accessor: 'id',
    disableSortBy: true,
    id: `seats.voicemail.share.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
  {
    Header: i18next.t('phone_system:containers.seats.voicemail.table.column.name.header'),
    accessor: 'name',
  },
  {
    Header: i18next.t('phone_system:containers.seats.voicemail.table.column.number.header'),
    accessor: 'mailbox',
  },
  {
    Header: i18next.t('phone_system:containers.seats.voicemail.table.column.pin.header'),
    Cell: ({ value }: Cell<JSX.Element>) => <TableCellMaskedValue value={value} />,
    accessor: 'pin',
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
  },
  {
    Header: i18next.t(
      'phone_system:containers.seats.voicemail.table.column.transcription_enabled.header',
    ),
    Cell: ({ value }: Cell<JSX.Element>) => <TableCellCheckmarkIcon isVisible={value} />,
    accessor: 'transcribe',
    id: `seats.voicemail.transcription_enabled.${Justify.Center}`,
    width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.LARGE,
  },
];

/**
 * @name getTableData
 * @description Get the table data array.
 *
 * @param fieldValues
 * @param data
 *
 * @returns Data array.
 */
export const getTableData = (fieldValues: FieldValues, data: VoicemailResponse = []): any[] =>
  uniqBy([...Object.values(fieldValues?.voicemail ?? {}), ...data], 'id').filter(
    (item) => !fieldValues?.voicemail?.[item.id]?._toDelete && item.id,
  );
