import {
  HookFormInputWrapper,
  HookFormSelectWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import LabeledDesktopAppZoneSelect from 'apps/shared/components/LabeledDesktopAppZoneSelect';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { ADD_KEY, VALIDATION } from 'constant';
import {
  useDeleteAccountByIdMutation,
  useDemoteAsResellerByAccountIdMutation,
  usePatchAccountByIdMutation,
  usePromoteToResellerByAccountIdMutation,
} from 'models/Account';
import { useDeleteMattermostTeamMutation } from 'models/Mattermost';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogState,
} from 'shared/components/Dialog';
import { LabeledButton, LabeledInput, LabeledSelect, LabeledText } from 'shared/components/Labeled';
import { isContentEmpty } from 'shared/components/RichTextEditor';
import RichTextViewer from 'shared/components/RichTextViewer';
import useAccount from 'shared/hooks/useAccount';
import { useToast } from 'shared/hooks/useToast';
import { formatDate } from 'shared/utility/date';
import { TEMP_PROPERTY } from '../../../constant';
import { FormFields } from '../../../definition';
import useBuildRealm from '../../../hooks/useBuildRealm';
import { SectionProps as Props } from '../definition';
import AccountStatus from './components/AccountStatus';
import { AccountDialog } from './definition';
import {
  getDeleteAccountErrorMessage,
  getDialogs,
  getMattermostTeamActionOnAccountsStatusChange,
  getOptions,
  KEYWORD_DELETE,
} from './utility';

export { default as schema } from './schema';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = ['account_type', 'name', 'realm', 'language', 'timezone'];

const TEAM_CHAT_ENABLEMENT_PATH = 'ooma_info.team_chat.enabled';

const BasicSection = ({ id: accountId }: Props) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSuperDuper } = useAccount();
  const buildRealm = useBuildRealm();
  const { getValues, resetField, setValue, reset } = useFormContext();
  const exitConfirmationDialogCtx = useContext(ExitConfirmationDialogContext);
  const isAdd = accountId === ADD_KEY;

  const [patchAccountById] = usePatchAccountByIdMutation();
  const [deleteMattermostTeam] = useDeleteMattermostTeamMutation();
  const [deleteAccountById] = useDeleteAccountByIdMutation();
  const [demoteAsResellerById] = useDemoteAsResellerByAccountIdMutation();
  const [promoteToResellerById] = usePromoteToResellerByAccountIdMutation();

  const INIT_DIALOG_STATE = {
    delete: false,
    demote: false,
    disable: false,
    enable: false,
    promote: false,
  };
  const [isDialogOpen, setIsDialogOpen] = useState<DialogState>(INIT_DIALOG_STATE);
  const [deleteDialogInputValue, setDeleteDialogInputValue] = useState<string | undefined>();

  const updateAccount = async (id: string, key: string, value: boolean) => {
    let message = '';

    switch (key) {
      case 'enabled':
        await patchAccountById({ id, body: { [key]: value } });
        // This must happen after account disable
        await getMattermostTeamActionOnAccountsStatusChange({
          nextIsAccountEnabled: value,
          isTeamChatEnabled: getValues(TEAM_CHAT_ENABLEMENT_PATH),
          deleteMattermostTeam,
          accountId,
        })();

        message = value
          ? t('accounts_manager:containers.accounts.section.basics.field.enable_account.toast')
          : t('accounts_manager:containers.accounts.section.basics.field.disable_account.toast');
        break;
      case 'is_reseller':
        if (value) {
          await promoteToResellerById({ id });
          message = t(
            'accounts_manager:containers.accounts.section.basics.field.promote_account.toast',
          );
        } else {
          await demoteAsResellerById({ id });
          message = t(
            'accounts_manager:containers.accounts.section.basics.field.demote_account.toast',
          );
        }
        break;
      default:
        break;
    }

    setValue(key, value);
    setIsDialogOpen(INIT_DIALOG_STATE);

    showToast.success(message);
  };

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        setIsDialogOpen(INIT_DIALOG_STATE);
        break;
      case 'saveClicked':
        {
          const id = accountId as string;

          if (isDialogOpen.demote) {
            await updateAccount(id, 'is_reseller', false);
          }
          if (isDialogOpen.promote) {
            await updateAccount(id, 'is_reseller', true);
          }
          if (isDialogOpen.disable) {
            await updateAccount(id, 'enabled', false);
          }
          if (isDialogOpen.enable) {
            await updateAccount(id, 'enabled', true);
          }

          if (isDialogOpen.delete && deleteDialogInputValue === KEYWORD_DELETE) {
            try {
              // This must happen before account deletion
              if (getValues(TEAM_CHAT_ENABLEMENT_PATH)) {
                await deleteMattermostTeam({ accountId: id }).unwrap();
              }
              await deleteAccountById({ id }).unwrap();
              exitConfirmationDialogCtx.onIsDirtyChange?.(false); // Clean up nav blocker
              showToast.success(
                t('accounts_manager:containers.accounts.section.basics.field.delete_account.toast'),
              );
              navigate('..');
            } catch (exception) {
              showToast.error(getDeleteAccountErrorMessage(exception));
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const isDialogSaveDisabled = (name: string) =>
    name === 'delete' ? !(deleteDialogInputValue === KEYWORD_DELETE) : false;

  const handleDeleteDialogInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteDialogInputValue(e.target.value);
  };

  const DIALOG = getDialogs(setIsDialogOpen, getValues('name'), handleDeleteDialogInputChange);
  const OPTIONS = getOptions();

  const realm = getValues('realm');
  useEffect(() => {
    if (isAdd && realm === '' && buildRealm) {
      resetField('realm', { defaultValue: buildRealm('') });
    }
  }, [isAdd, realm, buildRealm, resetField]);

  return (
    <>
      <div role="row">
        <div role="cell">
          {!isAdd && (
            <>
              {/* Account Status */}
              <LabeledText
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_status.label',
                )}
                text={
                  <AccountStatus
                    date={t(
                      'accounts_manager:containers.accounts.section.basics.field.account_status.text',
                      { date: formatDate(getValues('created')) },
                    )}
                    status={
                      getValues('enabled')
                        ? t(
                            'accounts_manager:containers.accounts.section.basics.field.account_status.options.active',
                          )
                        : t(
                            'accounts_manager:containers.accounts.section.basics.field.account_status.options.disabled',
                          )
                    }
                  />
                }
              />

              {/* Account ID */}
              <LabeledText
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_id.label',
                )}
                text={getValues('id')}
              />
            </>
          )}

          {/* Account Type */}
          <HookFormSelectWrapper name="account_type" options={OPTIONS.ACCOUNT_TYPE}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                tooltip={
                  !isSuperDuper
                    ? t(
                        'accounts_manager:containers.accounts.section.basics.field.account_type.info',
                      )
                    : ''
                }
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_type.label',
                )}
                selectProps={{
                  ...formProps,
                  isDisabled: !isSuperDuper,
                }}
                labelProps={{ required: isSuperDuper }}
              />
            )}
          </HookFormSelectWrapper>

          {/* Account Name */}
          <HookFormInputWrapper name="name">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledInput
                id="input-basics-account-name"
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_name.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.basics.field.account_name.label',
                  ),
                  autoFocus: isAdd,
                  onChange: isAdd
                    ? (e: ChangeEvent<HTMLInputElement>) => {
                        setValue('name', e.target.value, { shouldDirty: true });
                        setValue('realm', buildRealm?.(e.target.value), { shouldDirty: true });
                      }
                    : onChange,
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>

          {/* Account Realm */}
          <HookFormInputWrapper name="realm">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_realm.info',
                  { max: VALIDATION.ACCOUNT.REALM.MAX },
                )}
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.account_realm.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.basics.field.account_realm.label',
                  ),
                  onChange: isAdd
                    ? (e: ChangeEvent<HTMLInputElement>) =>
                        setValue('realm', e.target.value, { shouldDirty: true })
                    : onChange,
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>

          {/* Desktop App Zone */}
          <LabeledDesktopAppZoneSelect name={TEMP_PROPERTY.DESKTOP_APP_ZONE} />

          {/* Timezone */}
          <HookFormTimeZoneSelectWrapper
            name="timezone"
            label={t('accounts_manager:containers.accounts.section.basics.field.timezone.label')}
          />

          {/* Language */}
          <HookFormSelectWrapper name="language" options={OPTIONS.LANGUAGE}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.language.label',
                )}
                selectProps={{
                  ...formProps,
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <div role="row" className="one-column">
        <div role="cell">
          {/* Notes */}
          {!isContentEmpty(getValues(FormFields.Notes)) && (
            <LabeledText
              label={t('accounts_manager:containers.accounts.section.basics.field.notes.label')}
              text={<RichTextViewer text={getValues(FormFields.Notes)} />}
              textWidth="auto"
            />
          )}
        </div>
      </div>

      {!isAdd && (
        <>
          <div role="row" className="one-column overline">
            <div role="cell">
              {/* Demote/Promote Account */}
              {isSuperDuper &&
                (getValues('is_reseller') ? (
                  <LabeledButton
                    label={t(
                      'accounts_manager:containers.accounts.section.basics.field.demote_account.label',
                    )}
                    tooltip={t(
                      'accounts_manager:containers.accounts.section.basics.field.demote_account.info',
                    )}
                    buttonProps={{
                      variant: 'outlined',
                      children: t(
                        'accounts_manager:containers.accounts.section.basics.field.demote_account.label',
                      ),
                      onClick: () => DIALOG.demote.handleClick(true),
                    }}
                  />
                ) : (
                  <LabeledButton
                    label={t(
                      'accounts_manager:containers.accounts.section.basics.field.promote_account.label',
                    )}
                    tooltip={t(
                      'accounts_manager:containers.accounts.section.basics.field.promote_account.info',
                    )}
                    buttonProps={{
                      variant: 'outlined',
                      children: t(
                        'accounts_manager:containers.accounts.section.basics.field.promote_account.label',
                      ),
                      onClick: () => DIALOG.promote.handleClick(true),
                    }}
                  />
                ))}
            </div>
          </div>

          <div role="row">
            <div role="cell">
              {/* Disable/Enable Account */}
              {getValues('enabled') ? (
                <LabeledButton
                  label={t(
                    'accounts_manager:containers.accounts.section.basics.field.disable_account.label',
                  )}
                  tooltip={t(
                    'accounts_manager:containers.accounts.section.basics.field.disable_account.info',
                  )}
                  buttonProps={{
                    variant: 'outlined',
                    children: t(
                      'accounts_manager:containers.accounts.section.basics.field.disable_account.label',
                    ),
                    onClick: () => DIALOG.disable.handleClick(true),
                  }}
                />
              ) : (
                <LabeledButton
                  label={t(
                    'accounts_manager:containers.accounts.section.basics.field.enable_account.label',
                  )}
                  tooltip={t(
                    'accounts_manager:containers.accounts.section.basics.field.enable_account.info',
                  )}
                  buttonProps={{
                    variant: 'outlined',
                    children: t(
                      'accounts_manager:containers.accounts.section.basics.field.enable_account.label',
                    ),
                    onClick: () => DIALOG.enable.handleClick(true),
                  }}
                />
              )}
            </div>
          </div>

          <div role="row">
            <div role="cell">
              {/* Delete Account */}
              <LabeledButton
                label={t(
                  'accounts_manager:containers.accounts.section.basics.field.delete_account.label',
                )}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.basics.field.delete_account.info',
                )}
                buttonProps={{
                  variant: 'outlined',
                  color: 'error',
                  children: t(
                    'accounts_manager:containers.accounts.section.basics.field.delete_account.label',
                  ),
                  onClick: () => DIALOG.delete.handleClick(true),
                }}
              />
            </div>
          </div>

          {Object.keys(DIALOG).map(
            (name: string): JSX.Element => {
              const dialog: AccountDialog = DIALOG[name];
              return (
                <Dialog
                  key={`dialog-${name}`}
                  open={isDialogOpen[name]}
                  isContentSelectable={
                    'isSelectable' in dialog.content ? dialog.content.isSelectable : true
                  }
                  title={dialog.title}
                  onClose={() => dialog.handleClick(false)}
                  renderActions={
                    <DialogActions
                      saveButtonProps={{
                        color: dialog.action.color,
                        label: dialog.action.label,
                        variant: 'contained',
                        disabled: isDialogSaveDisabled(name),
                      }}
                      onAction={handleDialogAction}
                    />
                  }
                >
                  {typeof dialog.content.element === 'string' ? (
                    <span>{dialog.content.element}</span>
                  ) : (
                    dialog.content.element
                  )}
                </Dialog>
              );
            },
          )}
        </>
      )}
    </>
  );
};

export default BasicSection;
