import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import VersionTag from 'apps/shared/components/VersionTag';
import { URL } from 'constant';
import { useFetchAppsQuery } from 'models/AppsStore';
import { FunctionComponent, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, RouteObject, useNavigate, useRoutes } from 'react-router-dom';
import AppError from 'shared/components/AppError';
import Error from 'shared/components/Error';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import ExternalLink from 'shared/components/ExternalLink';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import useSetNewRelicAttrs from 'shared/hooks/useSetNewRelicAttrs';
import { metaAccountsManager, metaCallCenter, metaNumbers, metaPhoneSystem } from './meta';
import AnnouncementDialog from './shared/components/AnnouncementDialog';
import AppLink from './shared/components/AppLink';
import NotificationDialog from './shared/components/E911NotificationDialog';
import { IframeApp } from './shared/components/IframeApp';
import { AppMeta } from './shared/definition';
import { checkMasqueradingAccountParam } from './shared/utility/account';
import { ExitConfirmationDialogContextProvider } from './store/providers';
import StyledApps from './style';
import { getApps } from './util';

export { CONSTANTS as APPS_CONSTANTS, style } from './style';

const Apps: FunctionComponent = (): JSX.Element => {
  /**
   * Importing Lazy like this causes a re-render on ever sub page change :(
   *
   * const AccountsManager = lazy(() => import('./AccountsManager'));
   * const CallCenter = lazy(() => import('./CallCenter'));
   * const PhoneSystem = lazy(() => import('./PhoneSystem'));
   *
   * TODO: Review app component lazy-loading options
   */
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentAccountId = useSelector((state: any) => state.account.id);
  const { data: appsStoreData, isError, error, isLoading } = useFetchAppsQuery();

  useSetNewRelicAttrs();

  const getRoutes: RouteObject[] = useMemo(() => {
    const routes = [];
    if (!!appsStoreData && !isLoading && !isError) {
      const { enabledApps, redirectApps } = getApps(appsStoreData, language);

      // compose the current page (here, the app selection page)
      const currentPageComponent = (
        <StyledApps>
          <div>
            <a href="#" onClick={() => navigate(-1)}>
              <Icon name="chevron-left-semifilled" size={40} />
              <span>{t('common:back')}</span>
            </a>
            {enabledApps.map((meta: any) => (
              <AppLink key={meta.slug} meta={meta} />
            ))}
          </div>
          <div>
            <VersionTag />
          </div>
        </StyledApps>
      );

      // dynamically build application routes
      const kosherApps = enabledApps.map((meta: any) => {
        const Component = meta.component;
        const {
          i18n: { label },
          slug,
          name,
        } = meta;

        const path = `/${slug}/*`;

        if (meta.isIframeApp) {
          return {
            path,
            element: <IframeApp label={label} title={name} />,
          };
        }

        return (
          Component && {
            path,
            element: <Component key={slug} label={label} />,
          }
        );
      });

      routes.push(...kosherApps);

      redirectApps.forEach((redirectApp) =>
        routes.push({
          path: `/${redirectApp.slug}/*`,
          element: <Navigate to="/apps" />,
        }),
      );

      routes.push({ path: '/', element: currentPageComponent });

      // append miscellaneous routes
      if (routes.length === 1) {
        [
          metaPhoneSystem,
          metaAccountsManager,
          metaNumbers,
          metaCallCenter,
        ].forEach((meta: AppMeta) =>
          routes.push({ path: `/${meta.slug}/*`, element: <Loading /> }),
        );
      } else {
        routes.push({ path: '*', element: <Error /> });
      }
    }
    return routes;
  }, [appsStoreData, isError, isLoading, language, navigate, t]);

  const apps = useRoutes(getRoutes);

  // check if an `accountId` is passed in the search params or
  // if we need to add an `accountId` in the search params
  useEffect(() => {
    checkMasqueradingAccountParam(dispatch, currentAccountId);
  });

  if (isError && (error as FetchBaseQueryError).status === 401) {
    return <Navigate to="/login" />;
  }

  return (
    <ErrorBoundary
      renderOnError={() => (
        <AppError>
          <>
            <Error message={t('common:component.app_error.error')} />
            <ExternalLink url={URL.SUPPORT.HOME}>
              {t('common:component.app_error.link')}
            </ExternalLink>
          </>
        </AppError>
      )}
    >
      <Suspense fallback={<Loading />}>
        <ExitConfirmationDialogContextProvider>{apps}</ExitConfirmationDialogContextProvider>
        <AnnouncementDialog />
        <NotificationDialog />
      </Suspense>
    </ErrorBoundary>
  );
};

export default Apps;
