import { svg } from 'apps/shared/components/HelpDialog';
import { actions, callflow } from './png';
import { arrow, callflow_0, callflow_1 } from './svg';

const images = {
  png: {
    actions,
    callflow,
  },
  svg: {
    arrow,
    callflow: [callflow_0, callflow_1],
    cursor: svg.cursor,
  },
};

export default images;
