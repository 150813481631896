import { DeviceType } from 'apps/PhoneSystem/definition';
import { REGEX } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { deviceMutations } from './Mutations';
import { deviceQueries } from './Queries';

export const schema = () =>
  Joi.object({
    // Basic Section
    name: Joi.string().min(1).max(128).required(), // .messages({'string.max': messages()['string.max'](128)}),
    device_type: Joi.string(),
    call_forward: Joi.when(Joi.ref('device_type'), {
      is: Joi.any().valid(DeviceType.Landline, DeviceType.Cellphone),
      then: Joi.object({
        number: Joi.string()
          .regex(REGEX.PHONE_NUMBER)
          .allow('')
          .messages(
            messages({
              'string.pattern.base': i18next.t('common:validation.general.phone_number'),
            }),
          ),
        direct_calls_only: Joi.boolean(),
        enabled: Joi.boolean(),
        failover: Joi.boolean(),
        ignore_early_media: Joi.boolean(),
        keep_caller_id: Joi.boolean(),
        require_keypress: Joi.boolean(),
        substitute: Joi.boolean(),
      }),
      otherwise: Joi.optional(),
    }),
    enabled: Joi.boolean(),
    follow_me: Joi.boolean(),
    suppress_unregister_notifications: Joi.boolean(),

    // Caller ID TODO should_create_caller_id field does not exist in the backend schema -> was mapped to "enabled"
    presence_id: Joi.string().empty(''),
    should_create_caller_id: Joi.boolean(),
    caller_id: Joi.object({
      internal: Joi.object({
        name: Joi.string().max(35).empty(''),
        number: Joi.string().max(35).empty(''),
      }),
      external: Joi.object({
        name: Joi.string().max(35).empty(''),
        number: Joi.string().max(35).empty(''),
      }),
      emergency: Joi.object({
        name: Joi.string().max(35).empty(''),
        number: Joi.string().max(35).empty(''),
      }),
    }),

    // SIP
    sip: Joi.object({
      // Realm is set by the server and not editable
      // realm: Joi.string().regex(realm).min(4).max(253),
      expire_seconds: Joi.number().integer(),
      invite_format: Joi.string().valid('username', 'npan', '1npan', 'e164', 'route', 'contact'),
      method: Joi.string().valid('ip', 'password'),
      username: Joi.when(Joi.ref('method'), {
        is: 'password',
        then: Joi.string().min(2).max(32).required(),
      }),
      password: Joi.when(Joi.ref('method'), {
        is: 'password',
        then: Joi.string().min(5).max(32).required(),
      }),
      ip: Joi.when(Joi.ref('method'), {
        is: 'ip',
        then: Joi.string().pattern(REGEX.STRING.IP_V4).required(),
      }),
    }).unknown(true),

    // Audio/Video
    media: Joi.object({
      audio: Joi.object({
        codecs: Joi.array().items(
          Joi.string().valid(
            'OPUS',
            'CELT@32000h',
            'G7221@32000h',
            'G7221@16000h',
            'G722',
            'speex@32000h',
            'speex@16000h',
            'PCMU',
            'PCMA',
            'G729',
            'GSM',
            'CELT@48000h',
            'CELT@64000h',
            'G722_16',
            'G722_32',
            'CELT_48',
            'CELT_64',
            'Speex',
            'speex',
          ),
        ),
      }),
      video: Joi.object({
        codecs: Joi.array().items(Joi.string().valid('H261', 'H263', 'H264', 'VP8')),
      }),

      peer_to_peer: Joi.string(),
    }).unknown(true),

    // Call Restrictions
    call_restriction: Joi.object({
      tollfree_us: Joi.link('#call_restriction'),
      toll_us: Joi.link('#call_restriction'),
      emergency: Joi.link('#call_restriction'),
      emergency_au: Joi.link('#call_restriction'),
      social_svc: Joi.link('#call_restriction'),
      caribbean: Joi.link('#call_restriction'),
      did_us: Joi.link('#call_restriction'),
      tollfree_au: Joi.link('#call_restriction'),
      australia: Joi.link('#call_restriction'),
      international: Joi.link('#call_restriction'),
      unknown: Joi.link('#call_restriction'),
    }).unknown(true),

    // Options
    // SEE ABOVE fax_option
    ringtones: Joi.object({
      internal: Joi.string().max(256).empty(''),
      external: Joi.string().max(256).empty(''),
    }),
    exclude_from_queues: Joi.boolean(),
    ignore_completed_elsewhere: Joi.boolean(),
    contact_list: Joi.object({
      exclude: Joi.boolean(),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());

export const {
  useFetchDevicesQuery,
  useLazyFetchDevicesQuery,
  useFetchDeviceByIdQuery,
  useFetchDeviceStatusesQuery,
  useFetchDevicesByOwnerIdQuery,
} = deviceQueries;

export const {
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useCreateDeviceMutation,
  usePatchDeviceMutation,
  useRebootDeviceMutation,
} = deviceMutations;
