import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from 'shared/components/Box';
import AddressAlert from './components/AddressAlert';
import defaultProps from './default';
import { FormAddressAlertRowProps as Props } from './definition';

const FormAddressAlertRow = (props: Props) => {
  const { hasMargin, field } = { ...defaultProps, ...props };
  const { getValues, watch } = useFormContext();
  const [number, setNumber] = useState<string>(getValues(field));
  const debouncedSetNumber = debounce(setNumber, 300);
  const watchNumber = watch(field);

  useEffect(() => {
    debouncedSetNumber(watchNumber);
  }, [watchNumber, debouncedSetNumber]);

  return (
    <Box role="row" className="one-column">
      <Box role="cell">
        <AddressAlert hasMargin={hasMargin} number={number} />
      </Box>
    </Box>
  );
};

export default FormAddressAlertRow;
