import { themeColor } from 'theme/local';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      padding: 0,
      '&.MuiTypography-h2': {
        padding: theme.spacing(1, 0),
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 600,
        '&.MuiTypography-block': {
          padding: theme.spacing(0, 0, 2),
          fontSize: 16,
        },
        '&.MuiTypography-error': {
          color: themeColor.core.color.red,
        },
      },
      '&.MuiTypography-help': {
        margin: '14px 0',
        padding: 'initial',
        lineHeight: 1.15,
        '&.MuiTypography-h1': {
          margin: theme.spacing(3, 0, 1),
          fontSize: 24,
          fontWeight: 500,
          '& + .MuiTypography-root': {
            margin: theme.spacing(0, 0, 3),
          },
        },
        '&.MuiTypography-h2': {
          fontSize: 17,
          fontWeight: 600,
        },
        '&.MuiTypography-h3': {
          fontSize: 15,
          fontWeight: 400,
        },
        '&.MuiTypography-h4': {
          fontSize: 18,
          fontWeight: 400,
        },
        '&.MuiTypography-help-content': {
          '&.MuiTypography-h2': {
            margin: theme.spacing(0, 0, 2),
            fontSize: 14,
            fontWeight: 400,
          },
        },
        '&.MuiTypography-help-intro': {
          '&.MuiTypography-h3': {
            margin: theme.spacing(5, 'auto', 0),
          },
          '&.MuiTypography-h4': {
            width: 380,
            margin: '0 auto',
          },
        },
      },
      '&.MuiTypography-level-title': {
        color: theme.palette.grey['600'],
        marginBottom: 1,
      },
    },
  },
};
