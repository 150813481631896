import { HelpDialogIntro, svg } from 'apps/shared/components/HelpDialog';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Switch from 'shared/components/Switch';
import Typography from 'shared/components/Typography';
import StyledHelpDialogContent from './style';
import translations from './translations';
import { getClasses } from './utility';

const HelpDialogContent = () => {
  const { dialog, panel } = translations();
  const classes = getClasses();

  return (
    <StyledHelpDialogContent>
      <Typography classes={classes.typography.default} variant="h1">
        {dialog.title}
      </Typography>
      <Typography classes={classes.typography.default} variant="h4">
        {dialog.subtitle}
      </Typography>
      <Box>
        <Box>
          <HelpDialogIntro number={1}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[0].intro.title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h4">
              {panel[0].intro.subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content content-0">
            <Typography classes={classes.typography.content} variant="h2">
              {panel[0].content[0].prompt}
            </Typography>
            <Box className="widget">
              <Box>
                <Box component="span">{panel[0].content[0].widget[0]}</Box>
                <Box component="span">
                  <Switch checked={false} onChange={() => {}} />
                </Box>
                <Box className="cursor cursor-0">{svg.cursor}</Box>
              </Box>
              <Box>
                <Box component="span">
                  <Switch checked={false} onChange={() => {}} />
                </Box>
                <Box component="span">{panel[0].content[0].widget[1]}</Box>
                <Box component="span">
                  <Switch checked onChange={() => {}} />
                </Box>
                <Box component="span">{panel[0].content[0].widget[2]}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={2}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[1].intro.title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h4">
              {panel[1].intro.subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content content-1">
            <Typography classes={classes.typography.content} variant="h2">
              {panel[1].content[0].prompt}
            </Typography>
            <Button startIcon={<Icon name="plus-circle-outlined" size={16} />} variant="contained">
              {panel[1].content[0].widget[0]}
            </Button>
            <Box className="cursor cursor-1">{svg.cursor}</Box>
          </Box>
          <Box className="content content-2">
            <Typography classes={classes.typography.content} variant="h2">
              {panel[1].content[1].prompt}
            </Typography>
            <Box className="widget">
              <Box>
                <Box component="span">
                  <Icon name="dropdown-down" subset={16} />
                </Box>
                <Box component="span">{panel[1].content[1].widget[0]}</Box>
                <Box component="span">{panel[1].content[1].widget[1]}</Box>
                <Box component="span">{panel[1].content[1].widget[2]}</Box>
                <Box component="span" />
              </Box>
              <Box>
                <Box component="span" />
                <Box component="span">{panel[1].content[1].widget[3]}</Box>
                <Box component="span">+16043466246</Box>
                <Box component="span">
                  <Switch checked onChange={() => {}} />
                </Box>
                <Box component="span">
                  <Icon name="edit" />
                  <Box className="cursor cursor-2">{svg.cursor}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledHelpDialogContent>
  );
};

export default HelpDialogContent;
