/**
 * @name getStateValue
 * @description Determine the application status.
 *
 * @param isEnabled object
 *
 * @returns Application status.
 */
export const getStateValue = (isEnabled: any) => {
  const values = Object.values(isEnabled);
  const count = values.filter((isEnabled: any) => isEnabled).length;

  if (values.length === count) {
    return 'all';
  }

  return count === 0 ? 'none' : 'indeterminate';
};
