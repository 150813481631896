import kebabCase from 'lodash/kebabCase';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { useE911AssignedEndpoints } from '../../../../../hooks/useE911AssignedEndpoints';
import DownloadEndpointsButton from './components/DownloadEndpointsButton';
import { LevelTitle } from './components/LevelTitle';
import { getLevelMaps } from './utility';

const Emergency911EndpointsSection: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const { isNumberAssignedToAccount, assignedUsers, assignedDevices } = useE911AssignedEndpoints(
    getValues('id'),
  );

  return (
    <>
      <h3>{t('numbers:containers.numbers_in_use.section.e911_endpoints.description')}</h3>
      <DownloadEndpointsButton
        isNumberAssignedToAccount={isNumberAssignedToAccount}
        assignedUsers={assignedUsers}
      />
      {getLevelMaps(isNumberAssignedToAccount, assignedUsers, assignedDevices).map(
        ({ levelText, listGetter }) => (
          <Box key={kebabCase(levelText.toLowerCase())} role="row" mb={2}>
            <Box role="cell">
              <LevelTitle value={levelText} />
              <Box role="cell">{listGetter()}</Box>
            </Box>
          </Box>
        ),
      )}
    </>
  );
};

export default Emergency911EndpointsSection;
