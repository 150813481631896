import { LOCAL_STORAGE } from 'constant';
import { useEffect } from 'react';
import useAccount from 'shared/hooks/useAccount';
import { setAttrs } from './utility';

const useSetNewRelicAttrs = () => {
  const { accountId, accountName } = useAccount();

  useEffect(() => {
    setAttrs({
      accountId,
      accountName,
      userId: localStorage.getItem(LOCAL_STORAGE.AUTH.USER_ID) as string,
    });
  }, [accountId, accountName]);
};

export default useSetNewRelicAttrs;
