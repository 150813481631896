import { EditFormProps as Props } from 'apps/PhoneSystem/definition';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeepKeys } from 'shared/utility';
import { fields, Section } from './components';

const Edit = ({ id }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const hasIcon = (mode: string, tabFields: Array<string> = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      tabFields.includes(field),
    ).length > 0;

  const items = [
    {
      isDirty: hasIcon('dirty', fields.options),
      isError: hasIcon('error', fields.options),
      component: <Section.Options id={id} />,
      hash: 'options',
      label: t(
        'phone_system:containers.account.submodule.site_locations.edit.section.options.label',
      ),
    },
    // NEMO-2234-NOTE: Hiding until backend supports
    // {
    //   isDirty: hasIcon('dirty', fields.callerId),
    //   isError: hasIcon('error', fields.callerId),
    //   component: <Section.CallerId />,
    //   hash: 'caller_id',
    //   label: t(
    //     'phone_system:containers.account.submodule.site_locations.edit.section.caller_id.label',
    //   ),
    // },
  ];

  const { Tabs, TabPanels } = useTabs({ items });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Edit;
