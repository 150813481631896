/**
 * @name getClasses
 * @description Get custom help classes.
 *
 * @returns Classes object.
 */
export const getClasses = () => {
  const root = {
    typography: 'MuiTypography-help',
  };

  return {
    typography: {
      default: { root: root.typography },
      intro: { root: `${root.typography} ${root.typography}-intro` },
    },
  };
};
