import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const StyledHelpDialogContent = styled(Box)`
  ${({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2.5)};
    text-align: center;

    > div {
      ${mixin.flex({ alignItems: 'stretch' })};
      margin: ${theme.spacing(3, 0, 0)};
      user-select: none;

      > div {
        width: calc(50% - 6px);
        padding: ${theme.spacing(2, 2, 4)};
        background-color: ${theme.core.background.grey.medium};

        &:last-of-type {
          margin: ${theme.spacing(0, 0, 0, 2)};
        }

        .content {
          position: relative;

          button {
            box-shadow: 0 4px 8px 4px rgba(0 0 0 / 25%) !important;
          }

          .cursor {
            position: absolute;

            &.cursor-0 {
              top: 78px;
              right: 105px;
            }

            &.cursor-1 {
              top: 60px;
              left: 250px;
            }

            &.cursor-2 {
              top: 96px;
              right: 14px;
            }
          }

          .widget {
            padding: ${theme.spacing(0, 2)};
            background-color: ${theme.core.color.white};

            > div {
              ${mixin.flex({ justifyContent: 'flex-start' })};
              padding: ${theme.spacing(2, 0)};

              span {
                ${mixin.font({ size: 14 })};
                text-align: left;
              }

              &:first-of-type > span {
                font-weight: 600;
                text-transform: uppercase;
              }
            }
          }

          &.content-0 .widget {
            padding: ${theme.spacing(3, 2)};

            > div {
              justify-content: center;

              &:first-of-type > span:first-of-type {
                margin: ${theme.spacing(0, 1, 0, 0)};
              }

              &:last-of-type > span {
                margin: ${theme.spacing(0, 0.66, 0, 0)};

                &:nth-of-type(2) {
                  margin: ${theme.spacing(0, 6, 0, 0)};
                }
              }
            }
          }

          &.content-1 {
            margin: ${theme.spacing(0, 0, 6)};
          }

          &.content-2 .widget > div {
            &:first-of-type {
              border-bottom: 1px solid ${theme.core.border.color};
            }

            &:last-of-type {
              padding: ${theme.spacing(1, 0)};
            }

            > span {
              --size-icon: 40px;
              flex-basis: calc((100% - var(--size-icon) * 2) / 3);
              flex-grow: 0;
              flex-shrink: 0;

              &:first-of-type,
              &:last-of-type {
                flex-basis: var(--size-icon);
                text-align: center;
              }
            }
          }
        }
      }
    }
  `}
`;

export default StyledHelpDialogContent;
