import { RouterLink } from 'apps/shared/components/RouterLink';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { Trans, useTranslation } from 'react-i18next';
import Alert from 'shared/components/Alert';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';
import { AddressAlertProps as Props } from './definition';
import { addPlusSign, getFullAddressString, isValidNumber } from './utility';

const AddressAlert = ({ hasMargin, number }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchPhoneNumbersQuery(undefined, {
    skip: !isValidNumber(number),
  });

  const currentNumber = data?.numbers?.[addPlusSign(number)];
  const fullAddress = getFullAddressString(currentNumber);
  const severity = fullAddress ? 'info' : 'warning';

  if (isLoading) {
    return <Loading />;
  }

  if (!isValidNumber(number) || !(currentNumber?.used_by || currentNumber?.used_by_callflow_id)) {
    return null;
  }

  return (
    <Alert
      hasIcon={false}
      hasMargin={hasMargin}
      action={
        <RouterLink to={`/apps/numbers/numbers-in-use/${number}`} target="_blank">
          <Button color={severity}>
            {t(
              'phone_system:containers.seats.section.caller_id.components.address_alert.button.label',
            )}
          </Button>
        </RouterLink>
      }
      className="MuiAlert-address"
      message={
        fullAddress ? (
          <Trans
            i18nKey="phone_system:containers.seats.section.caller_id.components.address_alert.message.0"
            values={{ address: fullAddress }}
          />
        ) : (
          t('phone_system:containers.seats.section.caller_id.components.address_alert.message.1')
        )
      }
      severity={severity}
    />
  );
};

export default AddressAlert;
