import { joiResolver } from '@hookform/resolvers/joi';
import StyledEditForm, { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import useTabs from 'apps/shared/hooks/useTabs';
import produce from 'immer';
import merge from 'lodash/merge';
import {
  settingsSchema,
  useFetchAccountCallerInfoMatchQuery,
  useFetchAccountQuery,
  useUpdateAccountByIdMutation,
  useUpdateAccountCallerInfoMatchByIdMutation,
} from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import {
  useDeleteMattermostTeamMutation,
  useEnableMattermostTeamMutation,
} from 'models/Mattermost';
import { FunctionComponent, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities, getDeepKeys } from 'shared/utility';
import { fields, Section } from './components';
import { BridgeNumberChangeAction } from './components/CellularEnablementSection/components/BridgeNumberSelect/definition';
import useUpsertBridgeCallflow from './components/CellularEnablementSection/components/BridgeNumberSelect/hooks/useUpsertBridgeCallflow';
import { FormFields } from './components/CellularEnablementSection/definition';
import { DataRestorationDialog } from './components/TeamChatEnablementSection/components/DataRestorationDialog';
import { useDataRestorationDialogState } from './components/TeamChatEnablementSection/hooks/useDataRestorationDialogState';
import { defaultValues } from './default';
import { FormInput, RequestPayload } from './definition';
import { getIsTeamChatIsEnabledDirty } from './utility/getIsTeamChatIsEnabledDirty';
import { getPathWhitelist } from './utility/getPathWhitelist';
import { updateMattermostTeam } from './utility/updateMattermostTeam';

const Settings: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data: accountData } = useFetchAccountQuery();
  const { data: callerInfoMatchData } = useFetchAccountCallerInfoMatchQuery();
  const [updateAccountById] = useUpdateAccountByIdMutation();
  const [updateCallerInfoMatchById] = useUpdateAccountCallerInfoMatchByIdMutation();
  const [enableMattermostTeam] = useEnableMattermostTeamMutation();
  const [deleteMattermostTeam] = useDeleteMattermostTeamMutation();
  const navigate = useNavigate();

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(settingsSchema()),
  });

  const {
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
    reset,
    resetField,
    watch,
  } = formMethods;
  const accountId = useSelector<string, string>(selectAccountId);

  const isPageDirty = checkFormEntities(dirtyFields);
  useExitConfirmationDialog({
    isDirty: isPageDirty,
    pathWhitelist: getPathWhitelist(),
  });

  const bridgeNumber = watch(FormFields.BridgeNumber);
  const bridgeNumberChangeAction = watch(FormFields.TempBridgeNumberChangeAction);

  const watchMusicOnHoldMediaId = watch(FormFields.MusicOnHoldMediaId);

  const upsertBridgeCallflow = useUpsertBridgeCallflow({
    initialNumber: accountData?.[FormFields.BridgeNumber],
    bridgeNumber,
    action: bridgeNumberChangeAction,
  });

  const dataRestorationDialogState = useDataRestorationDialogState();

  useEffect(() => {
    const transformedAccountData = produce(accountData, (draft: FormInput) => {
      if (accountData) {
        draft.max_connect_failures = accountData.max_connect_failures?.toString();
      }
    });

    reset(merge({}, defaultValues, transformedAccountData, callerInfoMatchData)); // Populates form components once data is loaded.
  }, [accountData, callerInfoMatchData]);

  useEffect(() => {
    if (watchMusicOnHoldMediaId === undefined) {
      resetField('music_on_hold.media_id');
    }
  }, [watchMusicOnHoldMediaId]);

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    try {
      const { screen_pop_enabled, screen_pop_platforms } = data;

      const isBridgeNumberDirty = dirtyFields[FormFields.BridgeNumber];
      const isCallerInfoMatchDirty = Object.keys(dirtyFields).every((field) =>
        fields.callerInfoMatch.includes(field),
      );

      const updateAccountByIdBody = produce(data, (draft: RequestPayload) => {
        // Transform form data type to request payload data type
        draft.max_connect_failures = parseInt(data.max_connect_failures, 10);

        // Remove temp field
        delete draft[FormFields.TempBridgeNumberChangeAction];

        // Remove bridge number field if unassign
        if (isBridgeNumberDirty && bridgeNumberChangeAction === BridgeNumberChangeAction.Unassign) {
          delete draft[FormFields.BridgeNumber];
        }

        // Remove bridge number if falsy value
        if (!draft[FormFields.BridgeNumber]) {
          delete draft[FormFields.BridgeNumber];
        }

        // Override team chat enablement field with original server data as we don't want to modify it
        if (Object.prototype.hasOwnProperty.call(draft.ooma_info, 'team_chat')) {
          draft.ooma_info.team_chat = accountData?.ooma_info?.team_chat;
        }
      });

      await Promise.all([
        updateAccountById({ id: accountId, body: updateAccountByIdBody }).unwrap(),
        isBridgeNumberDirty && upsertBridgeCallflow(),
        isCallerInfoMatchDirty &&
          updateCallerInfoMatchById({
            id: accountId,
            body: {
              screen_pop_enabled,
              screen_pop_platforms,
            },
          }).unwrap(),
      ]);

      // must call bizprov AFTER account update to make sure account update from bizprov side would not get override
      let isSuccess = true;
      isSuccess = await updateMattermostTeam({
        formState: data,
        deleteMattermostTeam,
        enableMattermostTeam,
        isTeamChatIsEnabledDirty: getIsTeamChatIsEnabledDirty(dirtyFields),
        openDataRestorationDialog: dataRestorationDialogState.onOpen,
        showErrorMessage: (errorMsg) => showToast.error(errorMsg),
      });
      if (!isSuccess) {
        return;
      }

      showToast.success();
      reset(merge({}, defaultValues, data));
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const { ActionRow, actionRowProps } = useActionRow({
    onSave: handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError })),
    onCancel: () => navigate('..'),
    hasDelete: false,
    hasSave: true,
    isDirty: isPageDirty,
    breadcrumbData: [],
  });

  const hasIcon = (mode: string, tabFields: Array<string> = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      tabFields.includes(field),
    ).length > 0;

  const items = [
    {
      hash: 'options',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.options.label',
      ),
      component: <Section.Options />,
      isDirty: hasIcon('dirty', fields.options),
      isError: hasIcon('error', fields.options),
    },
    {
      hash: 'caller-id',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.caller_id.label',
      ),
      component: <Section.CallerId />,
      isDirty: hasIcon('dirty', fields.callerId),
      isError: hasIcon('error', fields.callerId),
    },
    {
      hash: 'callcenter',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.call_center.label',
      ),
      component: <Section.CallCenter />,
      isDirty: hasIcon('dirty', fields.callCenter),
      isError: hasIcon('error', fields.callCenter),
    },
    {
      hash: 'caller_info_match',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.caller_info_match.label',
      ),
      component: <Section.CallerInfoMatch />,
      isDirty: hasIcon('dirty', fields.callerInfoMatch),
      isError: hasIcon('error', fields.callerInfoMatch),
    },
    {
      hash: 'sso',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.sso_and_idp.label',
      ),
      component: <Section.SSO />,
      isDirty: hasIcon('dirty', fields.sso),
      isError: hasIcon('error', fields.sso),
    },
    {
      hash: 'cellular_enablement',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.cellular_enablement.label',
      ),
      component: <Section.CellularEnablement />,
      isDirty: hasIcon('dirty', fields.cellularEnablement),
      isError: hasIcon('error', fields.cellularEnablement),
    },
    {
      hash: 'team_chat_enablement',
      label: t(
        'phone_system:containers.account.submodule.settings.edit_panel_sections.team_chat_enablement.label',
      ),
      component: <Section.TeamChatEnablement />,
      isDirty: hasIcon('dirty', fields.teamChatEnablement),
      isError: hasIcon('error', fields.teamChatEnablement),
    },
  ];

  const { Tabs, TabPanels } = useTabs({ items });

  return (
    <StyledEditForm>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>
        <EditPanel>
          {Tabs}
          {TabPanels}
        </EditPanel>
      </FormProvider>
      <DataRestorationDialog {...dataRestorationDialogState} />
    </StyledEditForm>
  );
};

export default Settings;
