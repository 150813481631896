import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledHelpDialog = styled(Box)`
  ${({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2.5)};
    text-align: center;

    > div {
      ${mixin.flex({ alignItems: 'stretch', justifyContent: 'space-around' })};
      margin: ${theme.spacing(3, 0, 0)};
      user-select: none;

      > div {
        height: 500px;
        padding: ${theme.spacing(2, 2, 4)};
        background-color: ${theme.core.background.grey.medium};

        &:not(:first-of-type) {
          margin: ${theme.spacing(0, 0, 0, 1)};
        }

        .intro {
          margin: ${theme.spacing(0, 0, 6)};

          span {
            ${mixin.boxShadow({ blurRadius: 8 })};
            ${mixin.font({ size: 26, weight: 700 })};
            display: inline-block;
            --size: 60px;
            width: var(--size);
            height: var(--size);
            margin: ${theme.spacing(2, 0, 4)};
            line-height: var(--size);
            background-color: ${theme.whitelabel.primary.color};
            border-radius: 50%;
            color: ${theme.core.color.white};
          }
        }

        .content {
          position: relative;

          button {
            box-shadow: 0 4px 8px 4px rgba(0 0 0 / 25%) !important;
          }

          .png,
          .svg {
            position: absolute;
          }
        }
      }
    }
  `};
`;
