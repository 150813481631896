import { themeColor } from 'theme';
import theme from 'theme/mui/core';

export default {
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2.66),
  whiteSpace: 'nowrap',
  '.MuiButton-dropdown-label': {
    display: 'inline-block',
    width: '100%',
    padding: theme.spacing(0, 2.66, 0, 0),
    textAlign: 'center',
  },
  '.MuiButton-startIcon': {
    margin: 0,
    padding: theme.spacing(0, 1.66, 0, 0),
  },
  '.MuiButton-endIcon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 12,
    minWidth: 12,
    height: 36,
    margin: theme.spacing(0.33, 0),
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    '.MuiSvgIcon-root': {
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  '&.MuiButton-dropdown-form-element': {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${themeColor.core.border.color}`,
    color: theme.palette.common.black,
    '&.MuiButton-dropdown-open .MuiButton-endIcon': {
      backgroundColor: themeColor.core.border.color,
    },
  },
  '&.MuiButton-dropdown-header-element': {
    height: 62,
    backgroundColor: themeColor.core.background.grey.light.color,
    border: 'none',
    borderLeft: `1px solid ${themeColor.core.divider.default}`,
    borderRadius: 0,
    color: themeColor.core.text.dark,
    '&:hover, &.MuiButton-dropdown-open': {
      backgroundColor: themeColor.core.background.grey.medium,
      '.MuiButton-endIcon': {
        backgroundColor: themeColor.core.background.grey.medium,
        svg: {
          fill: themeColor.whitelabel.primary.color,
        },
      },
    },
    '&.MuiButton-dropdown-open::after': {
      position: 'absolute',
      bottom: '-1px',
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: themeColor.core.background.grey.medium,
      content: '""',
    },
    '.MuiButton-endIcon': {
      height: 50,
      borderRadius: 0,
    },
    '.MuiButton-startIcon': {
      display: 'none',
    },
  },
  '&.MuiButton-dropdown-open .MuiButton-endIcon .MuiSvgIcon-root': {
    transform: 'rotate(-180deg)',
  },
  '&.MuiButton-containedSuccess.MuiButton-dropdown-open': {
    backgroundColor: theme.palette.success.dark,
  },
  '&.MuiButton-outlined': {
    '.MuiButton-endIcon': {
      borderLeftColor: theme.palette.secondary.main,
    },
    '&.Mui-disabled .MuiButton-endIcon': {
      borderLeftColor: themeColor.core.button.grey,
    },
    '&:hover .MuiButton-endIcon': {
      borderLeftColor: theme.palette.common.white,
    },
    '&.MuiButton-dropdown-open': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '.MuiButton-endIcon': {
        borderLeftColor: theme.palette.common.white,
      },
    },
  },
};
