import {
  AssignedDeviceValue,
  AssignedUserValue,
} from 'apps/Numbers/store/context/E911AssignedEndpointsContext/utility/definition';
import { CHARACTER } from 'constant';
import i18next from 'i18next';
import { EndpointCell } from './components/EndpointCell';

/**
 * @name getLevelMaps
 * @description Generates a list of level mappings for rendering UI components
 *
 * @param isNumberAssignedToAccount - Indicates whether the number is assigned at the account level
 * @param assignedUsers - List of assigned users
 * @param assignedDevices - List of assigned devices
 *
 * @returns A list of objects, each with a level text and a function to retrieve the UI component
 */
export const getLevelMaps = (
  isNumberAssignedToAccount: boolean,
  assignedUsers: Array<AssignedUserValue> | [],
  assignedDevices: Array<AssignedDeviceValue> | [],
) => [
  {
    levelText: i18next.t(
      'numbers:containers.numbers_in_use.section.e911_endpoints.account_level.title',
    ),
    listGetter: () =>
      isNumberAssignedToAccount
        ? i18next.t(
            'numbers:containers.numbers_in_use.section.e911_endpoints.account_level.assigned_true',
          )
        : CHARACTER.EMDASH,
  },
  {
    levelText: i18next.t(
      'numbers:containers.numbers_in_use.section.e911_endpoints.seats_level.title',
    ),
    listGetter: () =>
      assignedUsers?.length
        ? assignedUsers.map(({ username, first_name, last_name }) => (
            <EndpointCell key={username}>{`${username}, ${first_name} ${last_name}`}</EndpointCell>
          ))
        : CHARACTER.EMDASH,
  },
  {
    levelText: i18next.t(
      'numbers:containers.numbers_in_use.section.e911_endpoints.devices_level.title',
    ),
    listGetter: () =>
      assignedDevices?.length
        ? assignedDevices.map(({ device_name, username, first_name, last_name }) => (
            <EndpointCell key={username}>
              {`${device_name}${username ? ` (${username}, ${first_name} ${last_name})` : ''}`}
            </EndpointCell>
          ))
        : CHARACTER.EMDASH,
  },
];
