import Box from 'shared/components/Box';
import { HelpDialogIntroProps as Props } from './definition';
import StyledHelpDialogIntro from './style';

const HelpDialogIntro = ({ children, number }: Props) => (
  <StyledHelpDialogIntro>
    <Box component="span">{number}</Box>
    {children}
  </StyledHelpDialogIntro>
);

export default HelpDialogIntro;
