import AppIcon from 'apps/shared/components/AppIcon';
import Checkbox from 'shared/components/Checkbox';
import { DirtyIcon } from 'shared/components/Labeled';
import defaultProps from './default';
import { AppCheckboxProps as Props } from './definition';
import { map } from './map';
import StyledAppCheckbox from './style';

export { map } from './map';

const AppCheckbox = (props: Props) => {
  const { isDirty, label, name, iconProps, checkboxProps } = { ...defaultProps, ...props };

  const icon = iconProps?.name as string;
  const appIcon: string = map[icon] ? map[icon] : icon;
  const isChecked = checkboxProps?.checked as boolean;

  return (
    <StyledAppCheckbox key={name}>
      <Checkbox {...checkboxProps} id={name} data-test-id={name} />
      <AppIcon {...iconProps} name={appIcon} isDisabled={!isChecked} />
      <span>{label}</span>
      <DirtyIcon isDirty={isDirty} isInline />
    </StyledAppCheckbox>
  );
};

export default AppCheckbox;
