import i18next from 'i18next';
import { NotificationType } from '../../../definition';

const translations = (): Record<string, any> => ({
  [NotificationType.Deregister]: {
    heading: i18next.t(
      'accounts_manager:containers.accounts.section.notifications.heading.ip_phones_deregistration',
    ),
    field: {
      enabled: {
        info: i18next.t(
          'accounts_manager:containers.accounts.section.notifications.field.notify_on_deregister.info',
        ),
        label: i18next.t(
          'accounts_manager:containers.accounts.section.notifications.field.notify_on_deregister.label',
        ),
      },
    },
  },
  [NotificationType.SeatCreated]: {
    heading: i18next.t(
      'accounts_manager:containers.accounts.section.notifications.heading.seats_addition_and_deletion',
    ),
    field: {
      enabled: {
        info: i18next.t(
          'accounts_manager:containers.accounts.section.notifications.field.notify_on_seats_update.info',
        ),
        label: i18next.t(
          'accounts_manager:containers.accounts.section.notifications.field.notify_on_seats_update.label',
        ),
      },
    },
  },
});

export default translations;
