import { HelpDialogEdit, HelpDialogList } from './components';
import { HelpDialogType } from './components/definition';
import { HelpDialogProps as Props } from './definition';

export { HelpDialogType };

const HelpDialog = ({ type }: Props) => {
  switch (type) {
    case HelpDialogType.Edit:
      return <HelpDialogEdit />;
    case HelpDialogType.List:
      return <HelpDialogList />;
    default:
      return <></>;
  }
};

export default HelpDialog;
