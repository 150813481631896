/**
 * @name getClasses
 * @description Get the root classes value.
 *
 * @param [object]
 * @property hasMargin
 * @property className
 * @property id
 *
 * @returns Classes value.
 */
export const getClasses = ({
  hasMargin,
  className,
  id,
}: { hasMargin?: boolean; className?: string; id?: string } = {}) => {
  const classes = [];

  if (className) {
    classes.push(className);
  }

  if (id) {
    classes.push('MuiAlert-snackbar');
  }

  if (hasMargin) {
    classes.push('MuiAlert-hasMargin');
  }

  return classes.join(' ');
};
