import { CHARACTER, REGEX } from 'constant';

/**
 * @name addPlusSign
 * @description Prefix given number with a plus `+` sign.
 *
 * @param number
 *
 * @returns Number.
 */
export const addPlusSign = (number: string) => {
  if (!number) {
    return '';
  }
  return number.startsWith(CHARACTER.PLUS) ? number : `${CHARACTER.PLUS}${number}`;
};

/**
 * @name getFullAddressString
 * @description Build the full address value.
 *
 * @param numberData - E911 data object
 *
 * @returns Address value.
 */
export const getFullAddressString = (numberData: any) =>
  Object.values(
    (({ extended_address, locality, postal_code, region, street_address }) => ({
      // Note: Maintain the order of properties so that they correctly join in the returned string
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
    }))(numberData?.e911 ?? {}),
  )
    .filter((val) => val)
    .join(', ');

/**
 * @name isValidNumber
 * @description Determine validity of the given phone number.
 *
 * @param number - Phone number
 *
 * @returns Boolean
 */
export const isValidNumber = (number?: string): boolean =>
  number ? REGEX.PHONE_NUMBER.test(number) : false;
