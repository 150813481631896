import { useLazyFetchAccountQuery } from 'models/Account';
import { useLazyFetchDevicesQuery } from 'models/Device';
import { useLazyFetchUsersQuery } from 'models/User';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { E911AssignedEndpointsContext } from '.';
import { generateAssignedEndpointsMaps } from './utility';

export const E911AssignedEndpointsProvider = ({ children }: PropsWithChildren<any>) => {
  const [fetchUsers, { data: usersData }] = useLazyFetchUsersQuery();
  const [fetchAccount, { data: accountData }] = useLazyFetchAccountQuery();
  const [fetchDevices, { data: devicesData }] = useLazyFetchDevicesQuery();

  const assignedEndpoints = useMemo(
    () => generateAssignedEndpointsMaps({ usersData, accountData, devicesData }),
    [accountData, devicesData, usersData],
  );

  const fetchAssignedEndpoints = useCallback(() => {
    fetchAccount(undefined, true);
    fetchDevices(undefined, true);
    fetchUsers(undefined, true);
  }, [fetchAccount, fetchDevices, fetchUsers]);

  const value = useMemo(
    () => ({
      assignedEndpoints,
      fetchAssignedEndpoints,
    }),
    [assignedEndpoints, fetchAssignedEndpoints],
  );

  return (
    <E911AssignedEndpointsContext.Provider value={value}>
      {children}
    </E911AssignedEndpointsContext.Provider>
  );
};
