import styled from 'styled-components';
import { StyledHelpDialog } from '../style';

export const StyledHelpDialogEdit = styled(StyledHelpDialog)`
  > div > div {
    width: 33%;

    .content {
      .png {
        &.actions {
          top: 5px;
          right: -5px;
          width: 125px;
        }

        &.callflow {
          top: -10px;
          right: 40px;
          width: 220px;
        }
      }

      .svg {
        &.arrow {
          top: 68px;
          left: 140px;
          width: 50px;
          z-index: 1;
        }

        &.callflow-0 {
          width: 292px;
        }

        &.callflow-1 {
          width: 170px;
        }
      }
    }
  }
`;
