import MuiAlert, { AlertColor } from '@mui/material/Alert';
import merge from 'lodash/merge';
import { closeSnackbar } from 'notistack';
import { ForwardedRef, forwardRef } from 'react';
import Icon from '../Icon';
import { CONSTANTS } from './constant';
import { defaultProps } from './default';
import { AlertProps as Props } from './definition';
import { getClasses } from './utility';

const Alert = forwardRef((props: Partial<Props>, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    hasIcon,
    hasMargin,
    action,
    className,
    id,
    message,
    severity: ogSeverity,
    variant,
  } = merge({}, defaultProps, props);
  const severity = ogSeverity ?? (variant as AlertColor); // notistack uses the `variant` prop for `severity` values

  return (
    <MuiAlert
      action={
        id ? (
          <Icon
            hasHover
            name="close"
            size={CONSTANTS.ICON.SIZE.CLOSE}
            onClick={() => closeSnackbar(id)}
          />
        ) : (
          action
        )
      }
      classes={{ root: getClasses({ hasMargin, className, id }) }}
      data-test-id={`shared-alert${id ? '-snackbar' : ''}-${severity}`}
      iconMapping={{
        error: <Icon name="error-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
        info: <Icon name="info-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
        success: <Icon name="radiobutton-checked-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
        warning: <Icon name="warning-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
      }}
      ref={ref}
      severity={severity}
      {...(!hasIcon ? { icon: false } : {})}
    >
      {message}
    </MuiAlert>
  );
});

export default Alert;
