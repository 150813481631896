import { NotificationField, NotificationToType } from '../../../definition';
import { TEMP_PROPERTY_NOTIFICATIONS } from '../../constant';

export const getFormFields = (type: string) =>
  Object.freeze({
    ENABLED: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.Enabled}`,
    FROM: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.From}`,
    SUBJECT: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.Subject}`,
    SEND: {
      EMAIL_ADDRESSES: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.Send}.${NotificationField.EmailAddresses}`,
      TYPE: {
        ADMINS: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.Send}.${NotificationField.Type}.${NotificationToType.AccountAdmins}`,
        SPECIFIED: `${TEMP_PROPERTY_NOTIFICATIONS}.${type}.${NotificationField.Send}.${NotificationField.Type}.${NotificationToType.Specified}`,
      },
    },
  });
