import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { useCallRestrictionsSection } from 'apps/shared/hooks/useCallRestrictionsSection';
import useTabs from 'apps/shared/hooks/useTabs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDeepKeys } from 'shared/utility';
import { fields, Section } from './components/section';
import { FormProps as Props } from './definition';

const Form = ({ id }: Props) => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);

  const {
    component: callRestrictionsSection,
    fields: callRestrictionsFields,
  } = useCallRestrictionsSection({ hasClosedGroups: true, fields: fields.callRestrictions });

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const isTab = (mode: string, fields: string[] = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      fields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const items = {
    basics: {
      hash: 'basics',
      label: t('accounts_manager:containers.accounts.section.basics.label'),
      component: <Section.Basics id={id} />,
      isDirty: isTab('dirty', fields.basics),
      isError: isTab('error', fields.basics),
    },
    contacts: {
      hash: 'contacts',
      label: t('accounts_manager:containers.accounts.section.contacts.label'),
      component: <Section.Contacts />,
      isDirty: isTab('dirty', fields.contacts),
      isError: isTab('error', fields.contacts),
    },
    caller_id: {
      hash: 'caller_id',
      label: t('accounts_manager:containers.accounts.section.caller_id.label'),
      component: <Section.CallerId />,
      isDirty: isTab('dirty', fields.callerId),
      isError: isTab('error', fields.callerId),
    },
    notifications: {
      hash: 'notifications',
      label: t('accounts_manager:containers.accounts.section.notifications.label'),
      component: <Section.Notifications />,
      isDirty: isTab('dirty', fields.notifications),
      isError: isTab('error', fields.notifications),
    },
    call_restrictions: {
      hash: 'restrictions',
      label: t('accounts_manager:containers.accounts.section.call_restrictions.label'),
      component: callRestrictionsSection,
      isDirty: isTab('dirty', callRestrictionsFields),
      isError: isTab('error', callRestrictionsFields),
    },
    app_exchange: {
      hash: 'app_exchange',
      label: t('accounts_manager:containers.accounts.section.app_exchange.label'),
      component: <Section.AppExchange />,
      isDirty: isTab('dirty', fields.appExchange),
      isError: isTab('error', fields.appExchange),
    },
    notes: {
      hash: 'notes',
      label: t('accounts_manager:containers.accounts.section.notes.label'),
      component: <Section.Notes />,
      isDirty: isTab('dirty', fields.notes),
      isError: isTab('error', fields.notes),
    },
    // carrier_options: {
    //   hash: 'carrier_options',
    //   label: t('accounts_manager:containers.accounts.section.carrier_options.label'),
    //   component: <Section.CarrierOptions />,
    //   isDirty: isTab('dirty', fields.carrierOptions),
    //   isError: isTab('error', fields.carrierOptions),
    // },
    desktop_modules: {
      hash: 'desktop_modules',
      label: t('accounts_manager:containers.accounts.section.desktop_modules.label'),
      component: <Section.DesktopModules />,
      isDirty: isTab('dirty', fields.desktopModules),
      isError: isTab('error', fields.desktopModules),
    },
    // limits: {
    //   hash: 'limits',
    //   label: t('accounts_manager:containers.accounts.section.limits.label'),
    //   component: <Section.Limits />,
    //   isDirty: isTab('dirty', fields.limits),
    //   isError: isTab('error', fields.limits),
    // },
  };

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
