import Box from 'shared/components/Box';
import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const CONSTANTS = {
  SIZE: '60px',
};

const StyledHelpDialogIntro = styled(Box)`
  ${({ theme }) => css`
    margin: ${theme.spacing(0, 0, 6)};

    > span {
      ${mixin.boxShadow({ blurRadius: 8 })};
      ${mixin.font({ size: 26, weight: 700 })};
      display: inline-block;
      width: ${CONSTANTS.SIZE};
      height: ${CONSTANTS.SIZE};
      margin: ${theme.spacing(2, 0, 4)};
      line-height: ${CONSTANTS.SIZE};
      background-color: ${theme.whitelabel.primary.color};
      border-radius: 50%;
      color: ${theme.core.color.white};
    }
  `}
`;

export default StyledHelpDialogIntro;
