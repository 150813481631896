// User/index.ts
import { userMutations } from './Mutations';
import { userQueries } from './Queries';

export const {
  useFetchDesktopAppZoneByIdQuery,
  useFetchRootUserQuery,
  useFetchUserByIdQuery,
  useFetchUsersQuery,
  useLazyFetchUsersQuery,
  useLazyFetchUserByIdQuery,
} = userQueries;
export const {
  useCreateDesktopAppZoneMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  usePatchUserMutation,
  useUpdateUserMutation,
} = userMutations;

export const buildFullNameFromUser = ({ first_name, last_name }: User = {}): string => {
  const name = [];
  if (first_name) {
    name.push(first_name);
  }
  if (last_name) {
    name.push(last_name);
  }
  return name.join(' ');
};

export const buildExtensionAndFullNameFromUser = (user: User): string => {
  const { username } = user;
  if (isNaN(username)) {
    return buildFullNameFromUser(user);
  }
  return `${username}, ${buildFullNameFromUser(user)}`;
};

export const getFullNameFromUserListByUserId = (userId: string, users?: User[]): string => {
  if (!users || !users.length || !userId) {
    return '';
  }
  const user = users.find((user) => user.id === userId);
  return user ? buildFullNameFromUser(user) : '';
};

export const getExtensionAndFullNameFromUserListByUserId = (
  userId: string,
  users?: User[],
): string => {
  const user = users?.find((user) => user.id === userId);
  return user ? buildExtensionAndFullNameFromUser(user) : '';
};
