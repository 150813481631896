import {
  AssignedDeviceValue,
  AssignedEndpoints,
  AssignedEndpointsType,
  AssignedUserValue,
} from './definition';

export const generateAssignedEndpointsMaps = ({
  accountData,
  devicesData,
  usersData,
}: {
  accountData?: any;
  devicesData?: DeviceResponse;
  usersData?: UserResponse;
}): AssignedEndpoints => {
  // The lists of endpoints type that needed to be dynamically generated
  const assignedEndpointsLists = [
    {
      type: AssignedEndpointsType.Users,
      data: usersData,
      getter: ({ username, first_name, last_name }: any): AssignedUserValue => ({
        username,
        first_name,
        last_name,
      }),
    },
    {
      type: AssignedEndpointsType.Devices,
      data: devicesData,
      getter: ({ name, owner_id, device_type }: any): AssignedDeviceValue => {
        const assignedUser = usersData?.find((user) => user.id === owner_id) ?? {};

        return {
          device_name: name,
          username: assignedUser.username ?? '',
          first_name: assignedUser.first_name ?? '',
          last_name: assignedUser.last_name ?? '',
        };
      },
    },
  ];

  return {
    [AssignedEndpointsType.Account]: accountData?.caller_id?.emergency?.number,
    ...assignedEndpointsLists.reduce((assignedEndpoints: any, { type, data, getter }) => {
      assignedEndpoints[type] = Object.values(data ?? {}).reduce(
        (prev, { caller_id, ...value }) => {
          if (caller_id?.emergency?.number) {
            if (!prev.hasOwnProperty(caller_id.emergency.number)) {
              prev[caller_id.emergency.number] = [];
            }
            prev[caller_id.emergency.number].push(getter(value));
          }
          return prev;
        },
        {},
      );
      return assignedEndpoints;
    }, {}),
  };
};
