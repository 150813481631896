import { DEFAULT_TIMEZONE_OPTION_VALUE } from 'constant';
import { FormFields } from './definition';

export const defaultValues = {
  // options
  [FormFields.AddressCity]: '',
  [FormFields.AddressCountry]: '',
  [FormFields.AddressLine1]: '',
  [FormFields.AddressLine2]: '',
  [FormFields.AddressPostalCode]: '',
  [FormFields.AddressState]: '',
  [FormFields.DisplayName]: '',
  [FormFields.TimeZone]: DEFAULT_TIMEZONE_OPTION_VALUE,

  // caller id
  // NEMO-2234-NOTE: Hiding until backend supports
  // [FormFields.CallerId]: {
  //   [CallerIdType.Emergency]: {
  //     name: '',
  //     number: '',
  //   },
  //   [CallerIdType.External]: {
  //     name: '',
  //     number: '',
  //   },
  // },
};
