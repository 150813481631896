import i18next from 'i18next';

const translations = () => ({
  dialog: {
    title: i18next.t('phone_system:containers.call_blocking.dialog.help.content.title'),
    subtitle: i18next.t('phone_system:containers.call_blocking.dialog.help.content.subtitle'),
  },
  panel: [
    {
      intro: {
        title: i18next.t(
          'phone_system:containers.call_blocking.dialog.help.content.panel_0.intro.title',
        ),
        subtitle: i18next.t(
          'phone_system:containers.call_blocking.dialog.help.content.panel_0.intro.subtitle',
        ),
      },
      content: [
        {
          prompt: i18next.t(
            'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.prompt',
          ),
          widget: [
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.label.block_anonymous_numbers',
            ),
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.label.disabled',
            ),
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.label.enabled',
            ),
          ],
        },
      ],
    },
    {
      intro: {
        title: i18next.t(
          'phone_system:containers.call_blocking.dialog.help.content.panel_1.intro.title',
        ),
        subtitle: i18next.t(
          'phone_system:containers.call_blocking.dialog.help.content.panel_1.intro.subtitle',
        ),
      },
      content: [
        {
          prompt: i18next.t(
            'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_0.prompt',
          ),
          widget: [
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_0.add_call_blocking',
            ),
          ],
        },
        {
          prompt: i18next.t(
            'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.prompt',
          ),
          widget: [
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.name',
            ),
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.numbers',
            ),
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.enabled',
            ),
            i18next.t(
              'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.list_a',
            ),
          ],
        },
      ],
    },
  ],
});

export default translations;
