import { transformRichTextStateToHtmlString } from 'apps/shared/components/HookForm/RichTextEditorWrapper';
import { EditorState } from 'draft-js';
import {
  TEMP_PROPERTY_HERO_APPS,
  TEMP_PROPERTY_IS_IN_HERO_APPS,
} from './components/section/DesktopModulesSection';
import { TEMP_PROPERTY_NOTIFICATIONS } from './components/section/NotificationsSection';
import { FormFields } from './definition';

export const NEW_ADMIN_PREFIX = 'new-';

/**
 * These additional (and temporary) account data object properties
 * are used to facilitate value gathering/validation, etc.
 */
export const TEMP_PROPERTY = {
  IS_IN_APPS_STORE: '_temp_is_in_apps_store',
  IS_IN_HERO_APPS: TEMP_PROPERTY_IS_IN_HERO_APPS,
  ANNOUNCEMENT: '_temp_announcement',
  APPS_STORE: '_temp_apps_store',
  DESKTOP_APP_ZONE: '_temp_desktop_app_zone',
  HERO_APPS: TEMP_PROPERTY_HERO_APPS,
  NOTES: '_temp_notes',
  NOTIFICATIONS: TEMP_PROPERTY_NOTIFICATIONS,
};

export const TEMP_PROPERTY_TRANSFORMERS: {
  [tempPropertyName: string]: {
    toFieldName: string;
    transformer: (editorState: EditorState) => string;
  };
} = {
  [TEMP_PROPERTY.NOTES]: {
    toFieldName: FormFields.Notes,
    transformer: transformRichTextStateToHtmlString,
  },
  [TEMP_PROPERTY.ANNOUNCEMENT]: {
    toFieldName: FormFields.Announcement,
    transformer: transformRichTextStateToHtmlString,
  },
};
