import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { CallRestriction } from 'definition';

export enum NotificationField {
  Cc = 'cc',
  Enabled = 'enabled',
  EmailAddresses = 'email_addresses',
  From = 'from',
  Send = 'send', // temporary form field
  Subject = 'subject',
  To = 'to',
  Type = 'type',
  UseVoicemailToEmailFrom = 'use_voicemail_to_email_from',
}

export enum NotificationToType {
  AccountAdmins = 'account_admins',
  Admins = 'admins', // legacy, superseded by `account_admins`
  Original = 'original',
  Specified = 'specified',
}

export enum NotificationType {
  Deregister = 'deregister',
  FaxInboundErrorToEmail = 'fax_inbound_error_to_email',
  VoicemailToEmail = 'voicemail_to_email',
  SeatCreated = 'new_seat_created',
  SeatDeactivated = 'seat_deactivated',
}

export interface Notifications {
  voicemail_to_email: {
    from: string;
  };
  fax_to_email: {
    from: string;
  };
  fax_inbound_error_to_email: {
    enabled: boolean;
    from: string;
    use_voicemail_to_email_from: boolean;
  };
  deregister: {
    enabled: boolean;
    from: string;
    send: {
      email_addresses?: Array<string>;
      type: Record<NotificationToType, boolean>;
    };
    subject: string;
  };
  new_seat_created: {
    enabled: boolean;
    from: string;
    send: {
      email_addresses?: Array<string>;
      type: Record<NotificationToType, boolean>;
    };
    subject: string;
  };
}

export interface FormInput {
  /** Basics */
  account_type: string;
  name: string;
  realm: string;
  timezone: string;
  language: string;
  is_reseller: boolean;
  enabled: boolean;

  /** Contact */
  contact: {
    billing: {
      name: string;
      number: string;
      email: string;
      street_address: string;
      locality: string;
      region: string;
      postal_code: string;
      country: string;
    };
    technical: {
      same_as_billing: boolean;
      name: string;
      number: string;
      email: string;
    };
  };

  /** Caller ID */
  caller_id: {
    [key in 'internal' | 'external' | 'emergency']: {
      name: string;
      number: string;
    };
  };
  format_from_uri: boolean;

  /** Call Restrictions */
  call_restriction: CallRestriction;

  /** Notifications */
  notifications: Notifications;

  /** Carrier Options */
  whitelabel: {
    carrier: string;
  };
}

export type SectionProps = TabPanelProps<FormInput>;
