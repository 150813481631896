import { HelpDialogIntro } from 'apps/shared/components/HelpDialog';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Typography from 'shared/components/Typography';
import images from '../images';
import { getClasses } from '../utility';
import { StyledHelpDialogList } from './style';
import translations from './translations';

const HelpDialogList = () => {
  const { dialog, panel } = translations();
  const classes = getClasses();

  return (
    <StyledHelpDialogList>
      <Typography classes={classes.typography.default} variant="h1">
        {dialog.title}
      </Typography>
      <Typography classes={classes.typography.default} variant="h3">
        {dialog.subtitle}
      </Typography>
      <Box>
        <Box>
          <HelpDialogIntro number={1}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[0].title}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <Button startIcon={<Icon name="plus-circle-outlined" size={16} />} variant="contained">
              {panel[0].content}
            </Button>
            <Box className="svg cursor">{images.svg.cursor}</Box>
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={2}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[1].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[1].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <Box className="svg callflow-0">{images.svg.callflow[0]}</Box>
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={3}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[2].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[2].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <Box className="svg callflow-1">{images.svg.callflow[1]}</Box>
            <Box className="svg arrow">{images.svg.arrow}</Box>
            <img src={images.png.actions} alt="actions" className="png actions" />
          </Box>
        </Box>
        <Box>
          <HelpDialogIntro number={4}>
            <Typography classes={classes.typography.intro} variant="h2">
              {panel[3].title}
            </Typography>
            <Typography classes={classes.typography.intro} variant="h3">
              {panel[3].subtitle}
            </Typography>
          </HelpDialogIntro>
          <Box className="content">
            <img src={images.png.callflow} alt="actions" className="png callflow" />
          </Box>
        </Box>
      </Box>
    </StyledHelpDialogList>
  );
};

export default HelpDialogList;
