import FormAddressAlertRow from 'apps/shared/components/FormAddressAlertRow';
import FormNumbersInUsePicker from 'apps/shared/components/FormNumbersInUsePicker';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { SectionProps as Props } from '../definition';

export { default as schema } from './schema';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'caller_id.internal.name',
  'caller_id.internal.number',
  'caller_id.external.name',
  'caller_id.external.number',
  'caller_id.emergency.name',
  'caller_id.emergency.number',
  'format_from_uri',
];

const CallerIdSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('accounts_manager:containers.accounts.section.caller_id.heading.in_house_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                  ),
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.placeholder',
                  ),
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('accounts_manager:containers.accounts.section.caller_id.heading.outbound_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                  ),
                }}
                isDirty={isDirty}
                feedback={feedback}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.label',
                )}
                inputProps={{
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('accounts_manager:containers.accounts.section.caller_id.heading.emergency_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                )}
                inputProps={{
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_name.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.label',
                )}
                inputProps={{
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.caller_id.field.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                feedback={feedback}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
          <FormNumbersInUsePicker field="caller_id.emergency.number" />
        </div>
      </div>

      <FormAddressAlertRow hasMargin />

      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="format_from_uri" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                label={t(
                  'accounts_manager:containers.accounts.section.caller_id.field.pass_realm_in_invite.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default CallerIdSection;
