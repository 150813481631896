import { ACTION_ROW_CONSTANTS } from 'shared/hooks/useActionRow';
import styled from 'styled-components';

export { default as EditPanel } from './components/EditPanel/index';

const StyledEditForm = styled.div`
  display: grid;
  grid-template: ${ACTION_ROW_CONSTANTS.HEIGHT.DEFAULT} 1fr / 1fr;
`;

export default StyledEditForm;
