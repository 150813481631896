import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import useBridgeNumber from 'shared/hooks/useBridgeNumber';
import DialogLink from '../Dialog/components/DialogLink';
import NumbersInUseDialog from './components/NumbersInUseDialog';
import { NumbersInUsePickerProps as Props } from './definition';
import { columns, prepareDataForTable } from './utility';

const NumbersInUsePicker = ({ onApply }: Props) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { data: phoneNumbersData, isLoading: isLoadingPhoneNumbers } = useFetchPhoneNumbersQuery();
  const { data: callflowsData, isLoading: isLoadingCallflows } = useFetchCallflowsQuery();
  const { bridgeNumber, isLoading: isAccountLoading } = useBridgeNumber();

  if (isAccountLoading || isLoadingCallflows || isLoadingPhoneNumbers) {
    return <Loading />;
  }

  return (
    <>
      <DialogLink
        isBelow
        icon="radiobutton-checked-outlined"
        iconSize={20}
        indentWidth="large"
        text={t('common:component.numbers_in_use_picker.button.label')}
        onClick={() => setIsDialogOpen(true)}
      />
      <NumbersInUseDialog
        isOpen={isDialogOpen}
        data={prepareDataForTable(phoneNumbersData, bridgeNumber, callflowsData)}
        columns={columns()}
        onApply={onApply}
        onCancel={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default NumbersInUsePicker;
