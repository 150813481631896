import {
  AssignedEndpoints,
  AssignedEndpointsType,
} from '../../../store/context/E911AssignedEndpointsContext/utility/definition';

export const getAssignedEndpointsByNumber = (
  assignedEndpoints: AssignedEndpoints,
  number?: string,
) => ({
  isNumberAssignedToAccount: assignedEndpoints[AssignedEndpointsType.Account] === number,
  [AssignedEndpointsType.Users]: assignedEndpoints[AssignedEndpointsType.Users][number ?? ''],
  [AssignedEndpointsType.Devices]: assignedEndpoints[AssignedEndpointsType.Devices][number ?? ''],
});
