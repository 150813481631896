import { useContext, useEffect, useMemo } from 'react';
import { E911AssignedEndpointsContext } from '../../store/context/E911AssignedEndpointsContext';
import { getAssignedEndpointsByNumber } from './utility';

/**
 * @name useE911AssignedEndpoints
 * @description
 * Get the assigned endpoints for a number in use
 *
 * @param number The number for getting the assigned endpoints
 */
export const useE911AssignedEndpoints = (number?: string) => {
  const { assignedEndpoints, fetchAssignedEndpoints } = useContext(E911AssignedEndpointsContext);

  // Initiate the query of endpoints data. Caching is managed by the context provider.
  useEffect(() => {
    fetchAssignedEndpoints();
  }, []);

  return useMemo(() => getAssignedEndpointsByNumber(assignedEndpoints, number), [
    assignedEndpoints,
    number,
  ]);
};
