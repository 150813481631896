import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { BaseSyntheticEvent, FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import { SectionProps as Props } from '../definition';

export const TEMP_PROPERTY_HERO_APPS = '_temp_hero_apps';
export const TEMP_PROPERTY_IS_IN_HERO_APPS = '_temp_is_in_hero_apps';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export let fields: string[] = [];

const DesktopModulesSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();

  const heroApps = getValues(TEMP_PROPERTY_HERO_APPS);
  const buildFieldName = (id: string, isKey = false): string =>
    `${TEMP_PROPERTY_IS_IN_HERO_APPS}${isKey ? '-' : '.'}${id}`;

  useEffect(() => {
    // dynamically update dirty fields
    const heroApps = getValues(TEMP_PROPERTY_HERO_APPS);

    if (heroApps) {
      Object.values(heroApps).forEach((app: any): void => {
        fields.push(buildFieldName(app.id));
      });
    }
  }, [getValues(TEMP_PROPERTY_HERO_APPS)]);

  const handleAppClick = (e: BaseSyntheticEvent, id: string): void => {
    const apps = getValues(TEMP_PROPERTY_HERO_APPS).map((app: any): any =>
      app.id === id ? { ...app, available: e.target.checked } : app,
    );

    setValue(TEMP_PROPERTY_HERO_APPS, apps);
    setValue(
      'hero_apps',
      apps.map((app: any): any => (app.available ? app.id : false)),
    );
    setValue(buildFieldName(id), e.target.checked);
  };

  return (
    <>
      <h2>
        {t('accounts_manager:containers.accounts.section.desktop_modules.heading.desktop_client')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Hero Apps */}
          {heroApps &&
            Object.values(heroApps).map(({ id, label }: any) => (
              <HookFormInputWrapper
                key={buildFieldName(id, true)}
                name={buildFieldName(id)}
                isCheckbox
              >
                {({ ref, isDirty, ...formProps }) => (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    indentWidth="small"
                    label={label}
                    checkboxProps={{
                      onClick: (e: BaseSyntheticEvent) => handleAppClick(e, id),
                      ...formProps,
                    }}
                  />
                )}
              </HookFormInputWrapper>
            ))}
        </div>
      </div>
    </>
  );
};

export default DesktopModulesSection;
