import { VALIDATION } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export default ({ isSuperDuper = false }) => ({
  account_type: isSuperDuper ? Joi.string().required() : Joi.optional(),
  name: Joi.string()
    .required()
    .min(VALIDATION.ACCOUNT.NAME.MIN)
    .max(VALIDATION.ACCOUNT.NAME.MAX)
    .pattern(VALIDATION.ACCOUNT.NAME.REGEX)
    .messages(
      messages({
        'string.pattern.base': i18next.t('common:validation.string.pattern_base'),
        'string.min': i18next.t('common:validation.string.min', {
          min: VALIDATION.ACCOUNT.NAME.MIN.toString(),
        }),
        'string.max': i18next.t('common:validation.string.max', {
          max: VALIDATION.ACCOUNT.NAME.MAX.toString(),
        }),
      }),
    ),
  realm: Joi.string()
    .required()
    .min(VALIDATION.ACCOUNT.REALM.MIN)
    .max(VALIDATION.ACCOUNT.REALM.MAX)
    .pattern(VALIDATION.ACCOUNT.REALM.REGEX)
    .messages(
      messages({
        'string.pattern.base': i18next.t('common:validation.string.pattern_base'),
        'string.max': i18next.t('common:validation.string.max', {
          max: VALIDATION.ACCOUNT.REALM.MAX.toString(),
        }),
      }),
    ),
  language: Joi.string().required(),
});
