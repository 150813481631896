import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent, FunctionComponent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { SectionProps as Props } from '../definition';

export { default as schema } from './schema';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'contact.billing.name',
  'contact.billing.email',
  'contact.billing.number',
  'contact.billing.street_address',
  'contact.billing.locality',
  'contact.billing.region',
  'contact.billing.postal_code',
  'contact.billing.country',
  'contact.technical.same_as_billing',
  'contact.technical.name',
  'contact.technical.email',
  'contact.technical.number',
];

const ContactsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    resetField,
    setValue,
    watch,
  } = useFormContext();

  const sameAsBilling = getValues('contact.technical.same_as_billing');
  const billing = getValues('contact.billing');

  const updateField = useCallback(
    (field: string, value: string, isBoth = false) => {
      if (isBoth) {
        setValue(`contact.billing.${field}`, value, { shouldDirty: true });
      }

      if (sameAsBilling) {
        const name = `contact.technical.${field}`;
        /**
         * TODO:
         * Replace the below conditional with logic similar to what
         * is in the notifications section: `errors as FieldError & Contact`
         */
        if (
          errors.contact &&
          'technical' in errors.contact &&
          errors.contact?.technical?.[field as keyof typeof errors.contact.technical]
        ) {
          resetField(name, {
            defaultValue: getValues(`contact.billing.${field}`),
            keepDirty: false,
          });
        } else {
          setValue(name, value, { shouldDirty: false });
        }
      }
    },
    [errors, getValues, resetField, setValue, sameAsBilling],
  );

  useEffect(() => {
    if (sameAsBilling) {
      ['email', 'name', 'number'].forEach((field: string) => {
        updateField(field, billing[field]);
      });
    }
  }, [sameAsBilling, billing, updateField]);

  return (
    <>
      <h2>{t('accounts_manager:containers.accounts.section.contacts.heading.account_admins')}</h2>
      <p>{t('accounts_manager:containers.accounts.section.contacts.intro.account_admins')}</p>

      <h2>{t('accounts_manager:containers.accounts.section.contacts.heading.billing_contact')}</h2>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Name */}
          <HookFormInputWrapper name="contact.billing.name">
            {({ ref, isDirty, onChange, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.contact_name.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.contact_name.label',
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField('name', e.target.value, true),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Billing Contact - Number */}
          <HookFormInputWrapper name="contact.billing.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.contact_number.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.contact_number.label',
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField('number', e.target.value, true),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Email */}
          <HookFormInputWrapper name="contact.billing.email">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.contact_email.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.contact_email.label',
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField('email', e.target.value, true),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Street Address */}
          <HookFormInputWrapper name="contact.billing.street_address">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.street_address.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.street_address.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Billing Contact - City */}
          <HookFormInputWrapper name="contact.billing.locality">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t('accounts_manager:containers.accounts.section.contacts.field.city.label')}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.city.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Region */}
          <HookFormInputWrapper name="contact.billing.region">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.state_province.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.state_province.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Billing Contact - Postal Code */}
          <HookFormInputWrapper name="contact.billing.postal_code">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.postal_zip_code.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.postal_zip_code.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Billing Contact - Country */}
          <HookFormInputWrapper name="contact.billing.country">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.country.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.country.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>
        {t('accounts_manager:containers.accounts.section.contacts.heading.technical_contact')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Same as Billign Contact */}
          <HookFormInputWrapper name="contact.technical.same_as_billing" isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                hasMargin
                isDirty={isDirty}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.same_as_billing.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Technical Contact - Name */}
          <HookFormInputWrapper name="contact.technical.name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.technical_contact_name.label',
                )}
                inputProps={{
                  ...formProps,
                  disabled: watch('contact.technical.same_as_billing'),
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.technical_contact_name.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Technical Contact - Number */}
          <HookFormInputWrapper name="contact.technical.number">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.technical_contact_number.label',
                )}
                inputProps={{
                  ...formProps,
                  disabled: watch('contact.technical.same_as_billing'),
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.technical_contact_number.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
      <div role="row">
        <div role="cell">
          {/* Technical Contact - Email */}
          <HookFormInputWrapper name="contact.technical.email">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={t(
                  'accounts_manager:containers.accounts.section.contacts.field.contact_email.label',
                )}
                inputProps={{
                  ...formProps,
                  disabled: watch('contact.technical.same_as_billing'),
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.contacts.field.contact_email.label',
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default ContactsSection;
