import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export default () => ({
  caller_id: Joi.object({
    internal: Joi.object({
      name: Joi.string().max(35).allow(''),
      number: Joi.string().max(35).allow(''),
    }).unknown(true),
    external: Joi.object({
      name: Joi.string().max(35).required(),
      number: Joi.string().max(35).required(),
    }).unknown(true),
    emergency: Joi.object({
      name: Joi.string().max(35).required(),
      number: Joi.string().max(35).required(),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages()),
});
