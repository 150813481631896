import { FunctionComponent } from 'react';
import Checkbox from 'shared/components/Checkbox';
import InputLabel from 'shared/components/InputLabel';
import InputMessage from 'shared/components/InputMessage';
import * as utility from 'shared/utility';
import DirtyIcon from '../DirtyIcon';
import InfoIcon from '../InfoIcon';
import defaultProps from './default';
import { LabeledCheckboxProps as Props } from './definition';
import StyledLabeledCheckbox from './style';

export { getStyle } from './style';

const LabeledCheckbox: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    hasMargin,
    hasSmallMargin, // TODO: Replace with marginSize
    hasValidationMargin,
    isBelow,
    isCheckboxLeft,
    isInline,
    isDirty,
    checkboxProps,
    feedback,
    groupLabel,
    groupLabelProps,
    groupLabelWidth,
    id,
    indentWidth,
    label,
    labelProps,
    labelWidth,
    tooltip,
    tooltipWidth,
  }: Props = { ...defaultProps, ...props };
  const forId = id || utility.generateId('checkbox');

  const groupLabelElement = (
    <InputLabel {...groupLabelProps} width={groupLabelWidth}>
      {groupLabel}
    </InputLabel>
  );

  const labelElement = (
    <InputLabel htmlFor={forId} {...labelProps} width={labelWidth}>
      {label}
    </InputLabel>
  );

  return (
    <StyledLabeledCheckbox
      hasGroupLabel={Boolean(groupLabel)}
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      hasValidationMargin={hasValidationMargin}
      isBelow={isBelow}
      isCheckboxLeft={isCheckboxLeft}
      isInline={isInline}
      indentWidth={indentWidth}
      labelWidth={labelWidth}
      leftMargin={labelProps?.leftMargin}
    >
      {groupLabel && groupLabelElement}
      {!isCheckboxLeft && labelElement}
      <div>
        <div>
          <Checkbox id={forId} {...checkboxProps} />
          {isCheckboxLeft && labelElement}
          {tooltip && <InfoIcon isInline tooltip={tooltip} className={tooltipWidth} />}
          {hasDirtyIcon && <DirtyIcon isDirty={isDirty} isInline />}
        </div>
        {feedback && <InputMessage type={feedback.type} message={feedback.message} />}
      </div>
    </StyledLabeledCheckbox>
  );
};

export default LabeledCheckbox;
