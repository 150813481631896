import { metaPhoneSystem, metaSipTrunking } from 'apps/meta';
import { PhoneNumberUsedBy } from 'apps/Numbers/containers/NumbersInUse/List/definition';
import { meta as metaCallflows } from 'apps/PhoneSystem/containers/Callflows';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { TableCellStreetAddress } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import produce from 'immer';
import { Column } from 'react-table';
import { compareStrings } from 'shared/utility';

/**
 * @name getFilteredPhoneNumbers
 * @description Filters out the bridge number from the provided phone numbers data
 *
 * @param phoneNumbersData -  Phone numbers data
 * @param bridgeNumber - The account's bridge number
 *
 * @returns - A filtered array of phone number objects excluding the bridge number
 */
export const getFilteredPhoneNumbers = (phoneNumbersData: any, bridgeNumber: string) => {
  const tableDataObject = phoneNumbersData?.usage?.assigned ?? {};

  return Object.keys(tableDataObject)
    .map((currentKey) => tableDataObject[currentKey])
    .filter(({ id }) => id !== bridgeNumber);
};

/**
 * @name getUsedByValue
 * @description Returns a string based on the `used_by` value of a phone number
 *
 * @param usedBy - A string indicating what the phone number is used by
 * @param callflowId - The ID of the associated callflow
 * @param callflowsData - Callflows data
 * @param returnAsLink - Optional flag indicating whether to return the result as a link element
 *
 * @returns A descriptive string or a link element based on the `used_by` value of the phone number
 */
export const getUsedByValue = (
  usedBy: string | undefined,
  callflowId: string | undefined,
  callflowsData: any[] | undefined,
  returnAsLink?: boolean,
): string | null | JSX.Element => {
  const callflowName = callflowsData?.find(({ id }) => id === callflowId)?.name;

  switch (usedBy) {
    case PhoneNumberUsedBy.SmsBox:
      return i18next.t('common:sms_box') as string;
    case PhoneNumberUsedBy.SipTrunk:
      return returnAsLink ? (
        <RouterLink to={`/apps/${metaSipTrunking.slug}`}>
          {i18next.t('common:sip_trunk') as string}
        </RouterLink>
      ) : (
        (i18next.t('common:sip_trunk') as string)
      );
    case PhoneNumberUsedBy.Callflow:
      if (!callflowName) {
        return i18next.t('common:callflow_unavailable') as string;
      }

      return returnAsLink ? (
        <RouterLink to={`/apps/${metaPhoneSystem.slug}/${metaCallflows.slug}/${callflowId}`}>
          {callflowName}
        </RouterLink>
      ) : (
        callflowName
      );
    default:
      return null;
  }
};

/**
 * @name columns
 * @description Get the list of columns for the agents table
 *
 * @returns List of columns
 */
export const columns = (): Column[] => [
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.number',
    ) as string,
    accessor: 'id',
    width: 300,
  },
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.used_by',
    ) as string,
    accessor: 'used_by',
    sortType: (rowA: any, rowB: any) =>
      compareStrings(rowA.original.used_by, rowB.original.used_by),
    width: 300,
  },
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.service_address',
    ) as string,
    Cell: ({ row }: { row: { original: { e911: { street_address?: string } } } }) => (
      <TableCellStreetAddress streetAddress={row.original.e911?.street_address} />
    ),
    accessor: 'e911.street_address',
    width: 300,
  },
];

/**
 * @name prepareDataForTable
 * @description Prepares phone numbers data for a table by filtering out the bridge number
 *              and adding a descriptive `used_by` value
 *
 * @param phoneNumbersData - Phone numbers data
 * @param bridgeNumber - The account's bridge number
 * @param callflowsData - Callflows data
 *
 * @returns A new array of phone numbers with updated `used_by` values
 */
export const prepareDataForTable = (
  phoneNumbersData: any,
  bridgeNumber: string,
  callflowsData: any,
  hasUsedByAsLink?: boolean,
) =>
  produce(getFilteredPhoneNumbers(phoneNumbersData, bridgeNumber), (draft) => {
    draft.forEach((item, index) => {
      draft[index] = {
        ...item,
        used_by: getUsedByValue(item.used_by, item.used_by_callflow_id, callflowsData),
        ...(hasUsedByAsLink && {
          used_by_as_link: getUsedByValue(
            item.used_by,
            item.used_by_callflow_id,
            callflowsData,
            hasUsedByAsLink,
          ),
        }),
      };
    });
  });
