import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import {
  NotificationField,
  Notifications,
  NotificationToType,
  NotificationType,
} from '../definition';
import Notification from './components/Notification';
import { FORM_FIELDS, TEMP_PROPERTY_NOTIFICATIONS } from './constant';

export { I18NEXT_OPTIONS } from './components/Notification/constant';
export { default as schema } from './schema';
export { NotificationToType, NotificationType, TEMP_PROPERTY_NOTIFICATIONS };
export type { Notifications };

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: Array<string> = [];

const NotificationsSection = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    resetField,
    setValue,
    watch,
  } = useFormContext();

  const watchFields = {
    faxInboundUseVoicemail: watch(
      FORM_FIELDS.FAX_INBOUND_ERROR_TO_EMAIL.USE_VOICEMAIL_TO_EMAIL_FROM,
    ),
  };

  const updateField = useCallback(
    (field: string, value: string) => {
      const fieldName = `${TEMP_PROPERTY_NOTIFICATIONS}.${field}`;

      const local = {
        errors: errors?.[TEMP_PROPERTY_NOTIFICATIONS] as any,
        resetField: (name: string): void =>
          resetField(name, { defaultValue: getValues(name), keepDirty: false }),
        setValue: (name: string, value: string): void =>
          setValue(name, value, { shouldDirty: true }),
      };

      const setField = (isError: boolean, name: string, value: string): void =>
        isError ? local.resetField(name) : local.setValue(name, value);

      if (field === `${NotificationType.VoicemailToEmail}.${NotificationField.From}`) {
        const type = NotificationType.VoicemailToEmail;
        const FIELDS = FORM_FIELDS.FAX_INBOUND_ERROR_TO_EMAIL;

        setField(Boolean(local.errors?.[type]?.from), fieldName, value);

        if (getValues(FIELDS.USE_VOICEMAIL_TO_EMAIL_FROM)) {
          local.setValue(FIELDS.FROM, value);
        }

        if (local.errors?.[NotificationType.FaxInboundErrorToEmail]?.from) {
          local.resetField(FIELDS.FROM);
        }
      }

      if (field === `${NotificationType.FaxInboundErrorToEmail}.${NotificationField.From}`) {
        setField(
          Boolean(local.errors?.[NotificationType.FaxInboundErrorToEmail]?.from),
          fieldName,
          value,
        );
      }
    },
    [errors, getValues, resetField, setValue],
  );

  useEffect(() => {
    // dynamically update dirty fields
    Object.values(NotificationType).forEach((typeId: string) =>
      fields.push(`${TEMP_PROPERTY_NOTIFICATIONS}.${typeId}`),
    );
  }, []);

  useEffect(() => {
    if (watchFields.faxInboundUseVoicemail) {
      updateField(
        `${NotificationType.VoicemailToEmail}.${NotificationField.From}`,
        getValues(FORM_FIELDS.VOICEMAIL_TO_EMAIL.FROM),
      );
    }
  }, [watchFields.faxInboundUseVoicemail, getValues, updateField]);

  return (
    <>
      <h2>
        {t('accounts_manager:containers.accounts.section.notifications.heading.voicemail_to_email')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Send From */}
          <HookFormInputWrapper name={FORM_FIELDS.VOICEMAIL_TO_EMAIL.FROM}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.info',
                )}
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField(
                      `${NotificationType.VoicemailToEmail}.${NotificationField.From}`,
                      e.target.value,
                    ),
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>

          {/** Use voicemail_to_email Send From */}
          <HookFormInputWrapper
            isCheckbox
            name={FORM_FIELDS.FAX_INBOUND_ERROR_TO_EMAIL.USE_VOICEMAIL_TO_EMAIL_FROM}
          >
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isBelow
                isDirty={isDirty}
                indentWidth="large"
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.fax_to_email_use_same_values.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>
        {t('accounts_manager:containers.accounts.section.notifications.heading.fax_to_email')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Send From */}
          <HookFormInputWrapper name={FORM_FIELDS.FAX_INBOUND_ERROR_TO_EMAIL.FROM}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.info',
                )}
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                  ),
                  disabled: Boolean(watchFields.faxInboundUseVoicemail),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField(
                      `${NotificationType.FaxInboundErrorToEmail}.${NotificationField.From}`,
                      e.target.value,
                    ),
                }}
                labelProps={{
                  required: !watchFields.faxInboundUseVoicemail,
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Fax-To-Email Notifications */}
          <HookFormInputWrapper isCheckbox name={FORM_FIELDS.FAX_INBOUND_ERROR_TO_EMAIL.ENABLED}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                indentWidth="large"
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.fax_inbound_error_to_email.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <Notification type={NotificationType.Deregister} />
      <Notification type={NotificationType.SeatCreated} />
    </>
  );
};

export default NotificationsSection;
