import styled from 'styled-components';
import { StyledHelpDialog } from '../style';

export const StyledHelpDialogList = styled(StyledHelpDialog)`
  > div > div {
    width: 25%;

    .content {
      .png {
        &.actions {
          top: 5px;
          right: -5px;
          width: 90px;
        }

        &.callflow {
          top: -10px;
          right: 5px;
          width: 200px;
          height: 230px;
        }
      }

      .svg {
        &.arrow {
          top: 45px;
          left: 100px;
          width: 40px;
          z-index: 1;
        }

        &.callflow-0 {
          left: 5px;
          width: 200px;
        }

        &.callflow-1 {
          width: 120px;
        }

        &.cursor {
          top: 30px;
          right: 70px;
        }
      }
    }
  }
`;
