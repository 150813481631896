import { HandleSaveSuccessParam } from '../../../../PhoneSystem/definition'; // TODO: Relocate

export enum FormFields {
  Announcement = 'announcement',
  Notes = 'custom_notes',
}

export type AccountInfoItem = Record<string, number | string>;

export interface EditProps {
  id: string;
  handleSaveSuccess: (params: HandleSaveSuccessParam) => void;
}

export interface FormProps {
  id?: string;
}

export interface HeroApp {
  available: boolean;
  description: string;
  id: string;
  label: string;
}

export interface OnAccountSubmitSuccessParams {
  isAdd: boolean;
  handleSaveSuccess: (params: HandleSaveSuccessParam) => void;
}
