import { useFilter } from 'apps/shared/hooks/useFilter';
import { ADD_KEY } from 'constant';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogType } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import HelpDialog, { HelpDialogType } from '../components/HelpDialog';
import { columns } from './utility';
import { filterOptions, filterUtilities } from './utility/filter';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const { data, isLoading } = useFetchCallflowsQuery();

  const { filter } = useFilter({
    data,
    heading: t('phone_system:containers.callflows.filter.heading.type'),
    utilities: filterUtilities(filterOptions()),
    setTableData,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      hasHelp
      hasSearch
      addButton={{
        hasAdd: true,
        label: t('phone_system:containers.callflows.table.action_row.button.add_callflow.label'),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      columns={columns()}
      data={tableData}
      filter={filter}
      help={{
        dialogComponent: (
          <Dialog open={isHelpOpen} type={DialogType.Help} onClose={() => setIsHelpOpen(false)}>
            <HelpDialog type={HelpDialogType.List} />
          </Dialog>
        ),
        iconTooltip: t('phone_system:containers.callflows.help'),
        setState: setIsHelpOpen,
      }}
      title={t('phone_system:containers.callflows.table.title')}
    />
  );
};

export default List;
